export const BASE_API = '/api';

export const SERVICE_TASK_BUSINESS = `/tasksbusiness-service`;
export const SERVICE_TASK_CANCELLATION = `/task-cancellation-service`;
export const SERVICE_USER_CLIENT_CATALOG = `/userclientcatalog-service`;
export const SERVICE_TASK_BUSINESS_RELAY = `/tasksbusiness-relay`;
export const SERVICE_FORCE_FILE = `/force-file-service`;
export const SERVICE_ITEM = `/items`;

export const ENDPOINT_GET_TASKS = `${SERVICE_TASK_BUSINESS}/task-templates/templates-with-submissions-status`;
export const ENDPOINT_GET_TASK_SIMPLE = ({ taskId }: { taskId: string }): string =>
  `${SERVICE_TASK_BUSINESS}/task-templates/simple/${taskId}`;
export const ENDPOINT_GET_TASK_SURVEY = ({ taskId }: { taskId: string }): string =>
  `${SERVICE_TASK_BUSINESS}/task-templates/survey/${taskId}`;
export const ENDPOINT_GET_TASK_PHOTO = ({ taskId }: { taskId: string }): string =>
  `${SERVICE_TASK_BUSINESS}/task-templates/image-recognition/${taskId}`;

export const ENDPOINT_GET_VISION_JOB_ID_LIST = `${SERVICE_TASK_BUSINESS}/config/image-recognition-configurations`;
export const ENDPOINT_GET_VISION_JOB_ID = ({ visionJobId }: { visionJobId: string }): string =>
  `${SERVICE_TASK_BUSINESS}/config/image-recognition-configurations/${visionJobId}`;
export const ENDPOINT_GET_TASK_DURATIONS = `${SERVICE_TASK_BUSINESS}/config/duration`;

export const ENDPOINT_CREATE_TASK_SIMPLE = `${SERVICE_TASK_BUSINESS}/task-templates/simple`;
export const ENDPOINT_CREATE_TASK_SURVEY = `${SERVICE_TASK_BUSINESS}/task-templates/survey`;
export const ENDPOINT_CREATE_TASK_PHOTO = `${SERVICE_TASK_BUSINESS}/task-templates/image-recognition`;

export const ENDPOINT_UPDATE_TASK_SIMPLE = ({ taskId }: { taskId: string }): string =>
  `${SERVICE_TASK_BUSINESS}/task-templates/simple/${taskId}`;
export const ENDPOINT_UPDATE_TASK_SURVEY = ({ taskId }: { taskId: string }): string =>
  `${SERVICE_TASK_BUSINESS}/task-templates/survey/${taskId}`;
export const ENDPOINT_UPDATE_TASK_PHOTO = ({ taskId }: { taskId: string }): string =>
  `${SERVICE_TASK_BUSINESS}/task-templates/image-recognition/${taskId}`;

export const ENDPOINT_CREATE_SUBMISSION = `${SERVICE_TASK_BUSINESS_RELAY}/submission`;
export const ENDPOINT_GET_SUBMISSIONS = ({ taskId }: { taskId: string }): string =>
  `${SERVICE_TASK_BUSINESS}/submission/task-templates/${taskId}`;
export const ENDPOINT_GET_SUBMISSION_CSV_PROGRESS = ({
  submissionId,
}: {
  submissionId: string;
}): string => `${SERVICE_TASK_BUSINESS}/submissionProgress/${submissionId}`;

export const ENDPOINT_DELETE_TASK = ({ taskId }: { taskId: string }): string =>
  `${SERVICE_TASK_BUSINESS}/task-templates/${taskId}`;

export const ENDPOINT_DELETE_TASK_PHOTO = ({ taskId }: { taskId: string }): string =>
  `${SERVICE_TASK_BUSINESS}/task-templates/image-recognition/${taskId}`;

export const ENDPOINT_DELETE_SUBMISSION = ({ submissionId }: { submissionId: string }): string =>
  `${SERVICE_TASK_BUSINESS}/submission/${submissionId}`;

export const ENDPOINT_GET_SUBMISSION_ACCOUNTS = ({
  submissionId,
}: {
  submissionId: string;
}): string => `${SERVICE_TASK_BUSINESS}/submission/${submissionId}/accounts`;

export const ENDPOINT_GET_SUBMISSIONS_STATUS_COUNTERS = ({ taskId }: { taskId: string }): string =>
  `${SERVICE_TASK_BUSINESS}/submission/counters/${taskId}`;

export const ENDPOINT_UPDATE_PRIORITY = `${SERVICE_TASK_BUSINESS}/task-templates/priority`;
export const ENDPOINT_PRIORITY_SYNC = `${SERVICE_TASK_BUSINESS}/task-templates/priority-sync`;

export const ENDPOINT_FILE_UPLOAD = `${SERVICE_FORCE_FILE}/upload-stream`;

export const ENDPOINT_GET_PARTNERS = (): string =>
  `${SERVICE_TASK_BUSINESS}/task-templates/info-partner`;

export const ENDPOINT_GET_TASK_CANCELLATION_REQUEST = ({
  requestId,
}: {
  requestId: string;
}): string => `${SERVICE_TASK_CANCELLATION}/task_cancellation_requests/${requestId}`;
export const ENDPOINT_GET_TASK_CANCELLATION_REQUESTS = `${SERVICE_TASK_CANCELLATION}/task_cancellation_requests`;
export const ENDPOINT_CREATE_TASK_CANCELLATION_REQUEST = `${SERVICE_TASK_CANCELLATION}/task_cancellation_requests`;
export const ENDPOINT_UPDATE_TASK_CANCELLATION_REQUEST = ({
  requestId,
}: {
  requestId: string;
}): string => `${SERVICE_TASK_CANCELLATION}/task_cancellation_requests/${requestId}`;
export const ENDPOINT_GET_BDR_ALL_INFO = ({ bdrId }: { bdrId: string }): string =>
  `${SERVICE_USER_CLIENT_CATALOG}/bdrs/${bdrId}`;
export const ENDPOINT_GET_CANCELLATION_CURRENT_PERIOD = `${SERVICE_TASK_CANCELLATION}/period`;

export const ENDPOINT_GET_ITEMS = `${SERVICE_ITEM}/items/v2`;
