import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  title: {
    pageTasks: 'Tarefas',
    filterPhotoTasks: 'Tarefas de foto',
    filterSimpleSurveyTasks: 'Tarefas simples e questionário',
    filterSponsoredTasks: 'Tarefas Patrocinadas',
    modalSubmissionForm: 'Enviar Tarefa',
    pageTaskCreateSimple: 'Nova tarefa simples',
    pageTaskUpdateSimple: 'Atualizar tarefa simples',
    pageTaskDuplicateSimple: 'Duplicação de tarefa simples',
    pageTaskCreateSurvey: 'Novo questionário',
    pageTaskUpdateSurvey: 'Atualizar questionário',
    pageTaskDuplicateSurvey: 'Duplicação de questionário',
    pageTaskCreatePhoto: 'Nova foto',
    pageTaskUpdatePhoto: 'Atualizar foto',
    pageTaskDuplicatePhoto: 'Duplicação de foto',
    pageTaskCancellationList: 'Task Cancellation Requests',
    pageTaskCancellationCreate: 'Open Cancellation Request',
    pageTaskCancellationUpdate: 'Update Cancellation Request',
    pageNotFound: 'Página não encontrada',
    pageLoading: 'Carregando...',
    pageError: 'Algo deu errado',
    modalDeleteTask: 'Excluir esta tarefa: "{taskName}"?',
    modalDeleteSubmission: 'Excluir este envio: "{submissionName}"?',
    modalConfirmDefault: 'Confirmação',
    modalConfirmChangeEffectivenessIDAddMode: {
      toMANUAL: "Mudar para o modo 'adicionar IDs manualmente'?",
      toCSV: "Mudar para o modo 'adicionar arquivo CSV'?",
    },
    modalConfirmChangeSubCategory: 'Deseja mudar a Subcategoria?',
    mandatory: 'Obrigatória?',
    formTask: 'Detalhes da tarefa',
    effectivenessCheck: 'Critério de efetividade',
    criteriaBdrIds: 'BDR IDs',
    modalSaveDraft: 'Save as draft?',
    criteriaCancellationPeriod: 'Cancellation Period',
  },
  subtitle: {
    modalSubmissionForm: 'Carregue o ficheiro CSV para publicar a tarefa para o público definido.',
    taskCancellationRequestCriteria: 'Select and fill the request criteria',
    criteriaBdrIds: 'Add BDR IDs',
    enterTaskCancellationNameReason: 'Enter the request name and reason for cancellation.',
    modalSaveDraftCancellationRequest:
      'Enter a request name, and save to be able to access this request later.',
    criteriaCancellationPeriod: 'Day, month or period in which the task will remain cancelled.',
  },
  table: {
    header: {
      taskSubmissions: 'Envios desta tarefa',
      taskSubmissionStatus: 'Status',
      taskSubmissionValidity: 'Vigência',
      taskCancellation: {
        name: 'Request name',
        reason: 'Reason',
        period: 'Cancellation Period',
        status: 'Status',
        actions: 'Actions',
      },
    },
  },
  sidebar: {
    title: {
      pageTasks: 'Gestão de Tarefas',
      pageOperations: 'Painel de operações',
    },
  },
  tooltip: {
    default: 'Veja essa dica',
    menu: 'Menu',
    expandTask: 'Expandir tarefa',
    noPermission: 'Não tem permissão',
    categoryDeprecated: 'The selected task category is no longer available. Select another one',
    task: {
      prioritize: 'Priorize esta tarefa para enviá-la',
      category: {
        disabled: '{profile} não pode executar tarefas de {category}',
      },
      subCategory: {
        disabled: '{subCategory} não está disponível',
        disabledInCategory: '{subCategory} não está disponível em {category}',
      },
      simple: {
        effectivenessCheck: {
          categoryNeeded:
            'É necessário selecionar uma categoria antes de adicionar o critério de efetividade',
          subCategoryDisabled: '{subCategory} só está disponível em {categories}',
          addId: 'Use Enter para adicionar IDs ou Vírgula para separar valores',
          addIdManualOrCsv:
            'Use Enter para adicionar IDs ou vírgula para separar valores ou enviar um CSV. Para verificar como o arquivo é construído, baixe o modelo abaixo',
        },
      },
      sponsored: 'As tarefas patrocinadas são sempre priorizadas pelo nosso algoritmo',
      survey: {
        reorder: 'Reordenar',
      },
      photo: {
        addVisionJobIdButton: 'Digite ou cole um vision job id para prosseguir',
        visionJobIdSelected:
          'Vision job id ja selecionado, para incluir outro, favor excluir o vision job id atual',
      },
    },
    submission: {
      delete: 'Cancelar envio',
      downloadCsv: 'Baixar lista de PDVs',
      csvFirst:
        'Você pode gerar a lista de AccountIDs usando seu banco de dados local ou preencher a lista manualmente com os AccountIDs em uma planilha. ',
      csvSecond:
        'Depois disso, insira o arquivo CSV aqui. Para saber como o arquivo é construído, baixe o modelo abaixo.',
      validityFirst:
        'A vigência é o período que a tarefa fica disponível para os RNs responderem/ executarem.',
      validitySecond:
        'Se a tarefa precisar ter vigência de somente um dia, selecione apenas a data de início.',
    },
    priorization: {
      needChange: 'Altere a priorização para confirmar',
      confirm: 'Confirmar',
      reorder: 'Reordenar',
      cancel: 'Cancelar',
    },
    taskCancellation: {
      viewRequest: 'View request',
      deleteRequest: 'Delete request',
    },
  },
  popper: {
    task: {
      duplicate: {
        reminder: {
          title: 'Lembre-se:',
          message: 'Altere o título da tarefa a ser duplicada para diferenciá-la da original.',
        },
      },
      viewOnly: {
        reminder: {
          title: 'Lembre-se:',
          message: 'Essa página é apenas para visualização e os dados não podem ser alterada.',
        },
      },
    },
    submission: {
      create: {
        validity:
          'A vigência é o período que a tarefa fica disponível para os RNs responderem/executarem. Se a tarefa precisar ter vigência de somente um dia, selecione apenas a data de início.',
        dropzoneFile:
          'Você pode gerar a lista de AccountIDs usando seu banco de dados local ou preencher a lista manualmente com os AccountIDs em uma planilha. Depois disso, insira o arquivo CSV aqui. Para saber como o arquivo é construído, baixe o modelo abaixo.',
      },
    },
  },
  toast: {
    success: {
      task: {
        create: 'Tarefa criada com sucesso!',
        update: 'Tarefa alterada com sucesso!',
        delete: 'Tarefa deletada com sucesso!',
        priority: 'Prioridade alterada com sucesso!',
      },
      taskCancellation: {
        create: 'New request created successfully',
        update: 'Request Updated',
      },
      submission: {
        create: 'Envio criado com sucesso!',
        delete: 'Envio deletado com sucesso!',
      },
    },
    error: {
      multipleFilesNotAllowed: 'Não é possível adicionar mais de um arquivo',
      task: {
        create: 'Ocorreu um erro durante a criação da Tarefa. Por favor, tente novamente.',
        update: 'Ocorreu um erro durante a alteração da Tarefa. Por favor, tente novamente.',
        delete: 'Ocorreu um erro durante a deleção da Tarefa. Por favor, tente novamente.',
        getTaskData:
          'Ocorreu um erro ao obter os dados da Tarefa. Por favor, tente novamente mais tarde.',
        effectivenessCheck: {
          idAlreadyAdded: 'Este ID já foi adicionado!',
        },
        priority: 'Ocorreu um erro durante a alteração da Prioridade. Por favor, tente novamente.',
        invalidFrequency: 'A frequência é inválida, selecione uma das opções disponíveis.',
      },
      taskCancellation: {
        getRequestData: 'An error occurred while getting Request data. Please try again later.',
        create: 'An error occurred while creating the Request. Please, try again later.',
        update: 'An error occurred while updating the Request. Please, try again later.',
      },
      submission: {
        create: 'Ocorreu um erro durante a criação do Envio. Por favor, tente novamente.',
        delete: 'Ocorreu um erro durante a deleção do Envio. Por favor, tente novamente.',
      },
    },
    warning: {
      task: {
        requiredField:
          'Campos obrigatórios não preenchidos! Verifique o formulário antes de enviar novamente.',
      },
    },
  },
  comboBox: {
    noOptions: 'Nenhuma opção encontrada',
  },
  message: {
    desc: {
      deleteTask:
        'Se excluir, todos os envios desta tarefa serão excluídos. Esta ação não pode ser desfeita.',
      deleteSubmission:
        'Se excluir, os RNs não poderão acessar esta tarefa nos PDVs definidos para este envio.',
      modalConfirmDefault: 'Você tem certeza de que quer fazer isso?',
      modalConfirmChangeEffectivenessIDAddMode: {
        toMANUAL: 'Se o fizer, o arquivo CSV será apagado definitivamente.',
        toCSV: 'Se o fizer, todos os IDs adicionados serão apagados definitivamente.',
      },
      modalConfirmChangeSubCategory:
        'Se o fizer, as informações da Subcategoria selecionada anteriormente serão apagadas definitivamente.',
      dropzoneCsvIds: 'Solte um arquivo .CSV com os IDs ou',
      categoryPhoto: '*Para tarefas de fotos, execução é a única categoria aceita.',
      criteriaBdrIdsQuantity: '{quantity} BDR IDs added',
    },
    loading: {
      default: 'Carregando...',
      waitAMoment: 'Aguarde alguns instantes...',
      validating: 'Validando, aguarde alguns instantes...',
    },
    success: {
      fileValidate: 'Arquivo validado!',
      priorityUpdated: 'Prioridade atualizada',
      validTaskCategory: 'Categoria de tarefa válida selecionada.',
    },
    error: {
      default: 'Ooops! Algo deu errado',
      request: 'Ocorreu um erro com sua requisição. Tente novamente mais tarde.',
      notFound: 'Mmm… Parece que esta página sumiu',
      notFoundSuggestion: 'Parece que esta página não foi encontrada ou não existe.',
      userWithoutGroups: 'Seu usuário não tem grupos permitidos',
      userWithoutVendorId: 'Seu usuário não tem um ID da empresa',
      userWithoutCountry: 'Seu usuário não tem um País',
      userWithoutCountryAndVendor: 'Seu usuário não tem um País e o ID da empresa',
      requestUserInfo:
        'Ocorreu um erro com a requisição dos seus dados de usuário. Tente novamente mais tarde.',
      getTaskOptions: 'Desculpe! As opções não foram encontradas. Por favor, tente novamente',
      optimizelyNotLoaded:
        'Desculpe! A conexão com o serviço optimizely não foi realizada com sucesso. Por favor, tente novamente',
      withoutResults: 'Desculpe! Nenhum resultado encontrado',
      searchEmptyList: 'Desculpe! Nenhum resultado encontrado para ',
      searchEmptyListSuggestion: 'Confira se você digitou as palavras corretamente.',
      tasksNotFound: 'Oops! Não há nenhuma tarefa para ser listada.',
      tasksNotFoundSuggestion: 'Tente alterar o filtro selecionado ou criar uma nova tarefa.',
      vendorUndefinedTaskList: 'Selecione um país e empresa disponíveis.',
      emptyFile: 'O arquivo está vazio',
      dropzoneEmpty: 'Nenhum arquivo foi inserido. ',
      dropzoneEmptySuggestion: 'Tente inserir algo!',
      invalidCsvTask: 'Insira um arquivo .CSV válido antes de enviar a tarefa. ',
      fileWrongExtension: 'Extensão de arquivo incorreta',
      fileMoreThanOneColumn: 'O arquivo tem mais de uma coluna',
      fileMoreThanTwoColumn: 'O arquivo tem mais de duas coluna',
      fileHaveDuplicatedAccounts: 'O arquivo contém contas duplicadas, revise e tente novamente.',
      fileWrongColumnName: 'Nome da coluna errado',
      multipleAnswer: 'Por favor, adicione pelo menos duas respostas a esta pergunta.',
      duplicatedAnswer:
        'As respostas não podem ser iguais. Por favor, digite um conteúdo diferente para cada resposta.',
      visionJobIdNotValid: 'ERRO: Vision Job ID selecionado inválido',
      selectVisionJobIdValid: 'Por favor, digite ou cole outro vision Job ID',
      duplicatedBdrId: 'BDR ID already entered. Try another one.',
      invalidBdrId: 'BDR ID not found. Enter another one.',
      invalidSku: 'SKU não encontrado. Digite outro.',
      someSkuInvalid: 'Alguns SKUs inválidos, baixe o relatório para informações detalhadas',
      allSkuInvalid: 'Todos SKUs inválidos, baixe o relatório para informações detalhadas.',
      hectoliterVolumeUnitInvalid:
        'A unidade deve ser hectolitros. Revise as informações e use o modelo acima.',
      packVolumeUnitInvalid:
        'A unidade deve ser Pacote. Revise as informações e use o modelo acima.',
      amountDifferentThanInteger:
        'O campo do valor requer números inteiros de 1 a 9999. Revise e tente novamente.',
      invalidVolumeAmount:
        'As tarefas de volume exigem um modelo de CSV adequado contendo IDs e valores de conta. Revise e tente novamente.',
    },
    alert: {
      setTaskPriority: 'Defina a prioridade desta tarefa',
      deprecatedTaskCategory:
        'A categoria selecionada para a tarefa não está mais disponível. Por favor, selecione novamente.',
      hectoliterSubmission: 'A unidade de validação definida nesta tarefa é hectolitros.',
      packSubmission: 'A unidade de validação definida nesta tarefa é pacote.',
    },
    info: {
      volumeUnit:
        'O valor da validação será definido no envio da tarefa, juntamente com o público.',
    },
  },
  button: {
    newTask: 'Nova tarefa',
    newSubmission: 'Enviar tarefa',
    openCancellationRequest: 'Open request',
    updateCancellationRequest: 'Update request',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    apply: 'Aplicar',
    saveChanges: 'Salvar alterações',
    tryAgain: 'Tente novamente',
    backToHome: 'Voltar para página inicial',
    tryAgainTaskOptions: 'clicando aqui.',
    createTask: 'Criar tarefa',
    saveCreateTask: 'Salvar nova tarefa',
    addEffectivenessCheck: 'Adicionar critério de efetividade',
    clearSearch: 'Limpar busca',
    clearSearchFilters: 'Limpar filtros',
    clearFilters: 'Limpar tudo',
    viewOnlyTask: 'Ver',
    viewAndEditTask: 'Visualizar e Editar',
    duplicateTask: 'Duplicar',
    deleteTask: 'Deletar',
    deleteSubmission: 'Excluir envio',
    send: 'Enviar',
    downloadModel: 'Baixar modelo',
    browseFiles: 'Navegue no seu dispositivo',
    newQuestion: 'Adicionar nova pergunta',
    tabs: {
      submit: 'Envios',
      priority: 'Prioridades',
    },
    jumpToQuestion: 'Pular para a pergunta',
    endQuestionary: 'Finalizar questionario',
    addNewAnswer: 'Adicionar Nova resposta',
    addVisionJobId: 'Incluir',
    filter: 'Filtros',
    openRequest: 'Open request',
    cancelTask: 'Cancel task',
    downloadCSV: 'Download CSV',
    discard: 'Discard',
    save: 'Save',
    downloadReport: 'Baixar Relatório',
  },
  formField: {
    placeholder: {
      submission: {
        name: 'Digite o nome do envio',
        validity: 'Selecione o período de validade',
        drop: 'Arraste para cá um arquivo CSV com os AccountIDs',
      },
      task: {
        search: 'Procurar por tarefa',
        name: 'Digite um nome para a tarefa (apenas visível no Admin Portal)',
        instruction: 'Digite uma instrução para a tarefa (será utilizado no BEES•Force pelos RNs)',
        category: 'Selecione uma categoria',
        simple: {
          effectivenessCheck: {
            main: 'Se o critério de efetividade não for adicionado, a tarefa será validada no momento que feita (completada) pelo {profile}.',
            id: {
              MONTHLY_PRODUCT_COVERAGE: 'Adicione os SKUs de Cobertura',
              COMBO: 'Adicionar ID do Combo',
              CHALLENGE: 'Adicionar ID do Desafio',
              VOLUME: 'Adicione os SKUs de Volume',
            },
            minimumQuantity: 'Insira a quantidade mínima',
            volumeUnit: 'Selecione a unidade',
          },
        },
        survey: {
          inputAnswerFreeText: 'Resposta do RN (Texto Livre)',
          inputAnswerNumeric: 'Resposta do RN (Numérico)',
          questionTitle: 'Digite a pergunta',
          selectValue: 'Selecione um valor',
          optionValue: 'Digite a resposta',
          selectCondition: 'Selecionar condição',
        },
        photo: {
          inputVisionJobId: 'Digite ou cole o Vision Job ID',
          visionJobIdSelected: 'Vision Job ID selecionado',
        },
      },
      taskCancellation: {
        search: 'Search by request name or reason',
        criteriaBdrIdsEmpty: 'Example: 018a8dce',
        criteriaBdrIds: 'Enter BDR ID',
        inputRequestName: 'Enter a request name.',
        inputRequestReason: 'Enter cancellation reason',
        requestName: 'Enter the request name',
        selectPeriod: 'Select period',
      },
    },
    label: {
      submission: {
        name: 'Nome do Envio',
        validity: 'Validade',
      },
      task: {
        sponsor: 'Parceiro',
        name: 'Nome da tarefa',
        instruction: 'Instrução de tarefa',
        category: 'Selecione uma categoria para a tarefa:',
        subCategory: 'Escolha uma subcategoria:',
        list: {
          selectProfileListTasks: 'PERFIL:',
          selectTaskType: {
            label: 'TIPO DE TAREFA:',
            sponsored: 'Patrocinada',
            nonSponsored: 'Não Patrocinada',
          },
        },
        create: {
          selectProfile: 'Quem realizará a tarefa?',
          selectTaskType: 'Que tipo de tarefa você deseja criar?',
        },
        simple: {
          duration: 'Selecione uma estimativa de tempo para completar a task:',
          frequency: 'Selecione uma frequência que esta tarefa deve estar disponível para o RN:',
          effectivenessCheck: 'Critério de efetividade',
          effectivenessId: {
            CHALLENGE: 'Adicione ID do Desafio',
            COMBO: 'Adicione ID do Combo',
            MONTHLY_PRODUCT_COVERAGE: 'SKU para validação',
            VOLUME: 'SKU para validação',
          },
          minimumQuantity: 'Quantidade mínima',
          volumeUnit: 'Unidade para validação',
        },
        sponsored: 'Tarefa Patrocinada',
        sponsoredPartnerLabel: 'Selecione o parceiro',
        survey: {
          questionary: {
            questionTitle: 'Pergunta numero',
          },
        },
        photo: {
          inputVisionJobId: 'Incluir Vision Job ID',
        },
      },
      taskCancellation: {
        requestName: 'Request Name',
        reason: 'Reason',
      },
    },
    value: {
      task: {
        type: {
          SIMPLE: 'Tarefa Simples',
          SURVEY: 'Questionário',
          IMAGE_RECOGNITION: 'Fotografia',
        },
        category: {
          CUSTOMER_SATISFACTION: 'Customer Satisfaction',
          DIGITAL_EDUCATION: 'Digital Education',
          EXECUTION: 'Execution',
          VALUE_CREATION: 'Value Creation',
          PORTFOLIO_EXPANSION: 'Portfolio Expansion',
          VOLUME_REVENUE: 'Volume & Revenue',
          MARKETPLACE_PORTFOLIO_EXPANSION: 'Marketplace Portfolio Expansion',
          MARKETPLACE_REVENUE: 'Marketplace Revenue',
          EXECUTION_ABI: 'Execution ABI',
          EXECUTION_MARKETPLACE: 'Execution Marketplace',
          MI_NEGOCIO: 'Mi Negocio',
          FINTECH: 'FinTech',
          CHALLENGE_REWARDS: 'Challenge and Rewards',
          ORDER_BEES: 'Order on BEES',
          DTAAS: 'DTaaS',
          SURVEY_DATA_COLLECTION: 'Survey/Data Collection',
          OTHER_TASKS: 'Other Tasks',
        },
        subCategory: {
          CHALLENGE: 'Desafio',
          COMBO: 'Combo',
          MONTHLY_PRODUCT_COVERAGE: 'Cobertura',
          SHELF: 'Prateleiras',
          COOLER: 'Geladeiras',
          POSTER: 'Poster',
          VOLUME: 'Volume',
        },
        simple: {
          frequency: {
            everyVisit: 'A cada visita',
            monthly: 'A cada 30 dias',
          },
        },
        survey: {
          responseType: {
            numeric: 'Numérico',
            freeText: 'Texto livre',
            checkBox: 'Seleção múltipla',
            radioButton: 'Seleção simples',
            conditional: 'Condicional',
          },
        },
      },
    },
    tip: {
      task: {
        type: {
          SIMPLE:
            'A tarefa simples é uma ação para os RNs executem durante a visita ao PDV. Este tipo consiste em um título e uma instrução.',
          SURVEY:
            'O tipo de questionário consiste em várias perguntas para os RNs respondem durante a visita ao PDV. As perguntas podem ter diferentes tipos de respostas, como seleção múltipla, seleção única, texto livre, numérico e sim/não.',
          IMAGE_RECOGNITION:
            'O tipo de tarefa de tirar foto permite que os RNs registrem facilmente os ativos do PDV, como refrigeradores ou materiais de trade marketing, usando a câmera embutida de seus telefones celulares. Este tipo de tarefa também está conectado à inteligência de reconhecimento de imagem (Image Recognition), que permite automatizar a operação de verificação de eficácia.',
        },
        simple: {
          frequency: {
            everyVisit:
              'A cada visita: A tarefa estará disponível em cada visita no PDV. No entanto, esta tarefa pode ser substituído por outra tarefa com prioridade mais alta.',
            monthly:
              'A cada 30 dias: A tarefa estará disponível para o RN em um intervalo de 30 dias entre as visitas.',
          },
        },
      },
    },
    error: {
      requiredField: 'Campo obrigatório',
      task: {
        requiredDuration:
          'Por favor, selecione uma opção de estimativa para a tarefa antes de salvar',
        requiredFrequency: 'Por favor, selecione uma frequência antes de salvar',
        requiredCategory: 'Por favor, selecione uma categoria para a tarefa antes de salvar',
        requiredSubCategory: 'Por favor, escolha uma subcategoria antes de salvar',
        requiredEffectivenessIDs: 'Por favor, adicione os IDs antes de salvar',
        requiredMinimumQuantity: 'Por favor, digite a quantidade mínima antes de salvar',
        requiredVolumeUnit: 'Por favor, selecione uma unidade de volume antes de salvar',
        requiredSponsoredBy: 'Por favor, selecione um parceiro antes de salvar',
        addVisionJobId: 'Para adicionar o vision job id selecionado, clique no botão "Incluir"',
      },
    },
    hint: {
      task: {
        simple: {
          effectivenessCheck: {
            minimumQuantity: 'Digite um valor até 9999',
          },
        },
      },
    },
  },
  submission: {
    noneSubmission: 'Nenhuma ativa',
    status: {
      FINISHED: 'Finalizada',
      SCHEDULED: 'Agendada',
      ACTIVE: 'Ativa',
      CONCLUDED: 'Finalizada',
      INVALID: 'Invalido',
      INVALIDS: 'Invalido',
      INPROGRESS: 'Processando',
      IN_PROGRESS: 'Processando',
    },
    list: {
      table: {
        taskSubmissions: 'Envios desta tarefa',
        status: 'Status',
        validity: 'Vigência',
      },
      dateFormat: 'dd/MM/yyyy',
      noValidity: 'Sem validade',
    },
    submit: {
      messages: {
        error: {
          finishedErrorAccountFoundDifferent:
            'Finalizado com erros, {accountFound} de {accountSize} foram processados',
          finishedErrorAccountDuplicated:
            'Finalizado com {accountDuplicated} de {accountSize} duplicados, {accountSubmitted} enviados',
          finishedErrorAccountInvalidAccountDuplicated:
            '{accountNotFound} inválidos, {accountDuplicated} duplicados, {accountSubmitted} enviados',
          invalidItems: 'Erro com arquivo CSV, itens inválidos',
        },
        inProgress: {
          default: 'Carregando CSV: {submissionProgress}',
        },
        success: {
          default: 'CSV processado com sucesso',
        },
      },
    },
  },
  csv: {
    header: {
      accountsId: 'accountId',
      accountsJustified: 'justificado',
      accountsAnswered: 'respondido',
      accountsNotAnswered: 'não respondido',
      accountsCancelled: 'cancelado',
      accountsCreated: 'criado',
    },
    content: {
      answerYes: 'sim',
      answerNo: 'não',
    },
    footer: {
      total: 'total',
    },
  },
  taskCancellation: {
    status: {
      CANCELLATION_REQUESTED: 'Cancellation requested',
      PROCESSED: 'Cancellation completed',
      NOT_PROCESSED: 'Cancellation not processed',
      DRAFT: 'Draft',
    },
  },
  pagination: {
    pageSize: 'Mostrar {pageSize} itens por página',
  },
  emptyState: {
    title: {
      noRequests: 'No requests yet',
    },
    desc: {
      noRequests: 'Your future requests will be displayed here.',
    },
  },
};

export default ptBR;
