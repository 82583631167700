export const STALE_TIME_MS = 5 * 60 * 1000;

export const RETRY_TIMES = 3;
export const RETRY_DELAY_MIN_MS = 2 * 1000;
export const RETRY_DELAY_MAX_MS = 10 * 1000;

export const DEBUG_INITIAL_IS_OPEN = false;

export const QUERY_KEY_TASK_LIST = 'task-list';
export const QUERY_KEY_TASK = 'task';
export const QUERY_KEY_TASK_DURATIONS = 'task-durations';

export const QUERY_KEY_SUBMISSION_LIST = 'submission-list';
export const QUERY_KEY_SUBMISSION_CSV_PROGRESS = 'submission-csv-progress';
export const QUERY_KEY_SUBMISSION_ACCOUNTS_CSV = 'submission-accounts';

export const QUERY_KEY_SUBMISSION_COUNTERS = 'submission-counters';

export const QUERY_KEY_PARTNERS = 'partners';
export const QUERY_KEY_VISION_JOB_ID_LIST = 'vision-job-id-list';
export const QUERY_KEY_VISION_JOB_ID = 'vision-job-id';

export const QUERY_KEY_TASK_CANCELLATION_REQUESTS_LIST = 'task-cancellation-requests-list';
export const QUERY_KEY_TASK_CANCELLATION_REQUEST_CSV = 'task-cancellation-request-csv';
export const QUERY_KEY_BDR_INFO = 'bdr-info';
export const QUERY_KEY_VALIDATE_BDR_LIST = 'validate-bdr-list';
export const QUERY_KEY_CANCELLATION_CURRENT_PERIOD = 'cancellation-current-period';
export const QUERY_KEY_TASK_CANCELLATION_REQUEST = 'task-cancellation-request';

export const QUERY_KEY_ITEM_INFO = 'item-info';
