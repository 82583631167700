import MessageMap from '../i18n.d';

const idID: MessageMap = {
  title: {
    pageTasks: 'Tugas',
    filterPhotoTasks: 'Tugas foto',
    filterSimpleSurveyTasks: 'Tugas Sederhana dan Survei',
    filterSponsoredTasks: 'Tugas yang Disponsori',
    modalSubmissionForm: 'Kirim Tugas',
    pageTaskCreateSimple: 'Tugas sederhana baru',
    pageTaskUpdateSimple: 'Perbarui tugas sederhana',
    pageTaskDuplicateSimple: 'Duplikasi tugas sederhana',
    pageTaskCreateSurvey: 'Survei baru',
    pageTaskUpdateSurvey: 'Perbarui survei',
    pageTaskDuplicateSurvey: 'Duplikasi survei',
    pageTaskCreatePhoto: 'Foto baru',
    pageTaskUpdatePhoto: 'Perbarui foto',
    pageTaskDuplicatePhoto: 'Duplikasi foto',
    pageTaskCancellationList: 'Permintaan Pembatalan Tugas',
    pageTaskCancellationCreate: 'Buka Permintaan Pembatalan',
    pageTaskCancellationUpdate: 'Perbarui Permintaan Pembatalan',
    pageNotFound: 'halaman tidak ditemukan',
    pageLoading: 'Memuat...',
    pageError: 'Ada yang salah',
    modalDeleteTask: 'Hapus tugas ini: {taskName}"?"',
    modalDeleteSubmission: 'Hapus kiriman ini: {submissionName}"?"',
    modalConfirmDefault: 'Konfirmasi',
    modalConfirmChangeEffectivenessIDAddMode: {
      toMANUAL: "Beralih ke mode 'tambahkan ID secara manual'?",
      toCSV: "Beralih ke mode 'masukkan file CSV'?",
    },
    modalConfirmChangeSubCategory: 'Apakah Anda ingin mengubah Subkategori?',
    mandatory: 'Wajib?',
    formTask: 'Detail tugas',
    effectivenessCheck: 'Pemeriksaan Efektivitas',
    criteriaBdrIds: 'ID BDR',
    modalSaveDraft: 'Simpan sebagai draf?',
    criteriaCancellationPeriod: 'Periode Pembatalan',
  },
  subtitle: {
    modalSubmissionForm: 'Unggah file CSV untuk mempublikasikan tugas ke audiens yang ditentukan.',
    taskCancellationRequestCriteria: 'Pilih dan isi kriteria permintaan',
    criteriaBdrIds: 'Tambahkan ID BDR',
    enterTaskCancellationNameReason: 'Masukkan nama permintaan dan alasan pembatalan.',
    modalSaveDraftCancellationRequest: '',
    criteriaCancellationPeriod: '',
  },
  table: {
    header: {
      taskSubmissions: 'Pengiriman tugas',
      taskSubmissionStatus: 'Status',
      taskSubmissionValidity: 'Keabsahan',
      taskCancellation: {
        name: 'Nama permintaan',
        reason: 'Alasan',
        period: 'Periode Pembatalan',
        status: 'Status',
        actions: 'Tindakan',
      },
    },
  },
  sidebar: {
    title: {
      pageTasks: 'Manajemen tugas',
      pageOperations: 'Panel operasi',
    },
  },
  tooltip: {
    default: 'Lihat tip ini',
    menu: 'Menu',
    expandTask: 'Perluas tugas',
    noPermission: 'Tidak punya izin',
    categoryDeprecated: 'Kategori tugas yang dipilih tidak lagi tersedia. Pilih yang lain',
    task: {
      prioritize: 'Prioritaskan tugas ini untuk mengirimkannya',
      category: {
        disabled: '{profile} tidak dapat tampil {category}',
      },
      subCategory: {
        disabled: '{subCategory} tidak tersedia',
        disabledInCategory: '{subCategory} tidak tersedia di {category}',
      },
      simple: {
        effectivenessCheck: {
          categoryNeeded:
            'Penting untuk memilih kategori sebelum menambahkan pemeriksaan efektivitas',
          subCategoryDisabled: '{subCategory} hanya tersedia di {categories}',
          addId: 'Gunakan Enter untuk menambahkan ID atau Koma untuk memisahkan nilai',
          addIdManualOrCsv: 'Retrieving data. Wait a few seconds and try to cut or copy again.',
        },
      },
      sponsored: 'Tugas bersponsor selalu diprioritaskan oleh algoritma kami',
      survey: {
        reorder: 'Susun ulang',
      },
      photo: {
        addVisionJobIdButton: 'Ketik atau tempelkan id tugas visi untuk melanjutkan',
        visionJobIdSelected:
          'ID tugas vision sudah dipilih, untuk menambahkan yang lain, harap hapus id tugas vision saat ini',
      },
    },
    submission: {
      delete: 'Batalkan pengiriman',
      downloadCsv: 'Unduh daftar POC',
      csvFirst:
        'Anda dapat membuat daftar ID akun menggunakan database lokal Anda, atau mengisi daftar secara manual dengan ID akun di spreadsheet.',
      csvSecond:
        'Setelah itu masukkan file CSV di sini. Untuk memeriksa bagaimana file dibuat, unduh model di bawah.',
      validityFirst:
        'Validitas adalah jangka waktu tugas tersedia untuk dijawab/dilaksanakan oleh BDR.',
      validitySecond: 'Jika tugas harus valid hanya untuk satu hari, pilih tanggal mulai saja.',
    },
    priorization: {
      needChange: 'Ubah prioritas untuk mengonfirmasi',
      confirm: 'Mengonfirmasi',
      reorder: 'Susun ulang',
      cancel: 'Membatalkan',
    },
    taskCancellation: {
      viewRequest: 'Lihat permintaan',
      deleteRequest: 'Hapus permintaan',
    },
  },
  popper: {
    task: {
      duplicate: {
        reminder: {
          title: 'Pengingat ramah:',
          message: 'Ubah judul Tugas untuk membedakan dari yang Anda duplikasi.',
        },
      },
      viewOnly: {
        reminder: {
          title: 'Pengingat ramah:',
          message: 'Halaman ini hanya dapat dilihat dan datanya tidak dapat diubah.',
        },
      },
    },
    submission: {
      create: {
        validity:
          'Validitas adalah jangka waktu tugas tersedia untuk dijawab/dilaksanakan oleh BDR. Jika tugas harus valid hanya untuk satu hari, pilih tanggal mulai saja.',
        dropzoneFile:
          'Anda dapat membuat daftar ID akun menggunakan database lokal Anda, atau mengisi daftar secara manual dengan ID akun di spreadsheet. Setelah itu masukkan file CSV di sini. Untuk memeriksa bagaimana file dibuat, unduh model di bawah.',
      },
    },
  },
  toast: {
    success: {
      task: {
        create: 'Tugas berhasil dibuat!',
        update: 'Tugas berhasil diperbarui!',
        delete: 'Tugas berhasil dihapus!',
        priority: 'Prioritas berhasil diperbarui!',
      },
      taskCancellation: {
        create: 'Permintaan baru berhasil dibuat',
        update: 'Permintaan Diperbarui',
      },
      submission: {
        create: 'Pengiriman berhasil dibuat!',
        delete: 'Kiriman berhasil dihapus!',
      },
    },
    error: {
      multipleFilesNotAllowed: 'Tidak dapat menambahkan lebih dari satu file',
      task: {
        create: 'Terjadi kesalahan saat membuat Tugas. Silakan coba lagi nanti.',
        update: 'Terjadi kesalahan saat memperbarui Tugas. Silakan coba lagi nanti.',
        delete: 'Terjadi kesalahan saat menghapus Tugas. Silakan coba lagi nanti.',
        getTaskData: 'Terjadi kesalahan saat mengambil data Tugas. Silakan coba lagi nanti.',
        effectivenessCheck: {
          idAlreadyAdded: 'ID ini telah ditambahkan!',
        },
        priority: 'Terjadi kesalahan saat memperbarui Prioritas. Silakan coba lagi nanti.',
        invalidFrequency: 'Frekuensi tidak valid, silakan pilih salah satu opsi yang tersedia.',
      },
      taskCancellation: {
        getRequestData:
          'Terjadi kesalahan saat mendapatkan data Permintaan. Silakan coba lagi nanti.',
        create: 'Terjadi kesalahan saat membuat Permintaan. Silakan coba lagi nanti.',
        update: 'Terjadi kesalahan saat memperbarui Permintaan. Silakan coba lagi nanti.',
      },
      submission: {
        create: 'Terjadi kesalahan saat membuat Kiriman. Silakan coba lagi nanti.',
        delete: 'Terjadi kesalahan saat menghapus Kiriman. Silakan coba lagi nanti.',
      },
    },
    warning: {
      task: {
        requiredField:
          'Bidang yang wajib diisi tidak terpenuhi! Silakan periksa formulir sebelum mengirimkannya lagi.',
      },
    },
  },
  comboBox: {
    noOptions: 'Tidak ada opsi yang ditemukan',
  },
  message: {
    desc: {
      deleteTask: 'Jika Anda melakukannya, semua penyerahan tugas ini akan dihapus.',
      deleteSubmission:
        'Jika Anda melakukannya, BDR tidak akan dapat mengakses tugas yang diserahkan ini pada POC yang ditentukan.',
      modalConfirmDefault: 'Apakah Anda yakin ingin melakukan ini?',
      modalConfirmChangeEffectivenessIDAddMode: {
        toMANUAL: 'Jika Anda melakukannya, file CSV pasti akan terhapus.',
        toCSV: 'Jika Anda melakukannya, semua ID yang ditambahkan pasti akan terhapus.',
      },
      modalConfirmChangeSubCategory:
        'Jika Anda melakukannya, informasi untuk subKategori yang dipilih sebelumnya akan dihapus secara permanen',
      dropzoneCsvIds: 'Letakkan file .CSV dengan ID atau',
      categoryPhoto: '*For tugas foto, eksekusi adalah satu-satunya kategori yang diterima.',
      criteriaBdrIdsQuantity: '{quantity} ID BDR ditambahkan',
    },
    loading: {
      default: 'Memuat...',
      waitAMoment: 'Tunggu sebentar...',
      validating: 'Memvalidasi, tunggu sebentar...',
    },
    success: {
      fileValidate: 'File divalidasi!',
      priorityUpdated: 'Prioritas diperbarui',
      validTaskCategory: 'Kategori tugas yang valid dipilih.',
    },
    error: {
      default: 'Ups! Ada yang salah',
      request: 'Terjadi kesalahan pada permintaan Anda. Silakan coba lagi nanti.',
      notFound: 'Mmm… Sepertinya halaman ini hilang',
      notFoundSuggestion: 'Sepertinya halaman ini tidak ditemukan atau tidak ada.',
      userWithoutGroups: 'Pengguna Anda tidak memiliki grup izin',
      userWithoutVendorId: 'Pengguna Anda tidak memiliki ID Perusahaan',
      userWithoutCountry: 'Pengguna Anda tidak memiliki Negara',
      userWithoutCountryAndVendor: 'Pengguna Anda tidak memiliki ID Negara dan Perusahaan',
      requestUserInfo:
        'Terjadi kesalahan pada permintaan data pengguna Anda. Silakan coba lagi nanti.',
      getTaskOptions: 'Maaf! Opsi tidak ditemukan. Silakan coba lagi',
      optimizelyNotLoaded: 'Maaf! Koneksi ke layanan optimal tidak berhasil. Silakan coba lagi',
      withoutResults: 'Ups, maaf! Tidak ada hasil yang ditemukan',
      searchEmptyList: 'Ups, maaf! Tidak ada hasil yang ditemukan untuk',
      searchEmptyListSuggestion: 'Pastikan Anda mengetikkan kata-kata dengan benar.',
      tasksNotFound: 'Ups! Tidak ada tugas untuk dicantumkan.',
      tasksNotFoundSuggestion: 'Coba ubah filter yang dipilih atau buat tugas baru.',
      vendorUndefinedTaskList: 'Pilih negara dan vendor yang tersedia.',
      emptyFile: 'Filenya kosong',
      dropzoneEmpty: 'Tidak ada file yang dimasukkan.',
      dropzoneEmptySuggestion: 'Cobalah untuk memasukkan sesuatu!',
      invalidCsvTask: 'Harap masukkan file .CSV yang valid sebelum mengirimkan tugas.',
      fileWrongExtension: 'Ekstensi file salah',
      fileMoreThanOneColumn: 'File memiliki lebih dari satu kolom',
      fileMoreThanTwoColumn: 'File memiliki lebih dari dua kolom',
      fileWrongColumnName: 'Nama kolom salah',
      multipleAnswer: 'Tolong, tambahkan setidaknya dua jawaban untuk pertanyaan ini.',
      duplicatedAnswer:
        'Jawaban tidak bisa sama. Silakan ketikkan konten yang berbeda untuk setiap jawaban.',
      visionJobIdNotValid: 'KESALAHAN: ID Pekerjaan Vision yang dipilih tidak valid',
      selectVisionJobIdValid: 'Silakan ketik atau tempel ID Pekerjaan vision lainnya',
      duplicatedBdrId: 'ID BDR sudah masuk. Coba yang lain.',
      invalidBdrId: 'ID BDR tidak ditemukan. Masukkan yang lain.',
      invalidSku: 'SKU tidak ditemukan. Masukkan yang lain.',
      someSkuInvalid: 'Beberapa SKU tidak valid, unduh laporan untuk informasi detailnya.',
      allSkuInvalid: 'Semua SKU tidak valid, unduh laporan untuk informasi detailnya.',
      hectoliterVolumeUnitInvalid:
        'Satuannya harus hektoliter. Tinjau informasinya dan pastikan untuk menggunakan template di atas.',
      packVolumeUnitInvalid:
        'Unit harus berupa Paket. Tinjau informasinya dan pastikan untuk menggunakan template di atas.',
      amountDifferentThanInteger:
        'Bidang jumlah memerlukan bilangan bulat dari 1 hingga 9999. Tinjau dan coba lagi.',
      invalidVolumeAmount:
        'Tugas volume memerlukan templat CSV yang sesuai yang berisi ID akun dan jumlah. Tinjau dan coba lagi.',
    },
    alert: {
      setTaskPriority: 'Tetapkan prioritas tugas ini',
      deprecatedTaskCategory:
        'Kategori tugas yang dipilih tidak lagi tersedia. Silakan pilih yang lain.',
      hectoliterSubmission: 'Satuan validasi yang ditetapkan dalam tugas ini adalah hektoliter.',
      packSubmission: 'Unit validasi yang ditetapkan dalam tugas ini adalah paket.',
    },
    info: {
      volumeUnit:
        'Jumlah validasi akan ditentukan pada saat penyerahan tugas bersama dengan audiens.',
    },
  },
  button: {
    newTask: 'Tugas baru',
    newSubmission: 'Kirim tugas',
    openCancellationRequest: 'Buka permintaan',
    updateCancellationRequest: 'Permintaan pembaruan',
    cancel: 'Membatalkan',
    confirm: 'Mengonfirmasi',
    apply: 'Menerapkan',
    saveChanges: 'Simpan perubahan',
    tryAgain: 'Coba lagi',
    backToHome: 'Kembali ke rumah',
    tryAgainTaskOptions: 'mengklik di sini.',
    createTask: 'Buat tugas',
    saveCreateTask: 'Simpan tugas',
    addEffectivenessCheck: 'Tambahkan pemeriksaan efektivitas',
    clearSearch: 'Bersihkan pencarian',
    clearSearchFilters: 'Hapus filter',
    clearFilters: 'Bersihkan semua',
    viewOnlyTask: 'Tampilan',
    viewAndEditTask: 'Lihat dan Edit',
    duplicateTask: 'Duplikat',
    deleteTask: 'Menghapus',
    deleteSubmission: 'Hapus kiriman',
    send: 'Kirim',
    downloadModel: 'Unduh modelnya',
    browseFiles: 'Jelajahi di perangkat Anda',
    newQuestion: 'Tambahkan pertanyaan baru',
    tabs: {
      submit: 'Kirim',
      priority: 'Prioritas',
    },
    jumpToQuestion: 'Langsung ke pertanyaan',
    endQuestionary: 'Selesaikan pertanyaan',
    addNewAnswer: 'Tambahkan jawaban baru',
    addVisionJobId: 'Menambahkan',
    filter: 'Filter',
    openRequest: 'Buka permintaan',
    cancelTask: 'Batalkan tugas',
    downloadCSV: 'Unduh CSV',
    discard: 'Membuang',
    save: 'Menyimpan',
    downloadReport: 'Unduh Laporan',
  },
  formField: {
    placeholder: {
      submission: {
        name: 'Masukkan nama pengiriman',
        validity: 'Pilih masa berlaku',
        drop: 'Letakkan di sini file .CSV dengan ID akun atau',
      },
      task: {
        search: 'Cari tugas',
        name: 'Ketikkan nama tugas (hanya terlihat di Portal Admin)',
        instruction: 'Ketik instruksi tugas (akan digunakan di BEES•Dipaksa oleh BDR)',
        category: 'Pilih Kategori',
        simple: {
          effectivenessCheck: {
            main: 'Jika pemeriksaan efektivitas tidak ditambahkan, tugas akan divalidasi pada saat itu {profile} menyelesaikannya.',
            id: {
              MONTHLY_PRODUCT_COVERAGE: 'Tambahkan SKU Cakupan',
              COMBO: 'Tambahkan ID Kombo',
              CHALLENGE: 'Tambahkan ID Tantangan',
              VOLUME: 'Tambahkan SKU Volume',
            },
            minimumQuantity: 'Masukkan jumlah minimum',
            volumeUnit: 'Pilih satuan',
          },
        },
        survey: {
          inputAnswerFreeText: 'Jawaban BDR (Teks Bebas)',
          inputAnswerNumeric: 'Jawaban BDR (Numerik)',
          questionTitle: 'Ketik pertanyaannya',
          selectValue: 'Pilih Nilai',
          optionValue: 'Ketik jawabannya',
          selectCondition: 'Pilih kondisi',
        },
        photo: {
          inputVisionJobId: 'Ketik atau tempel ID Pekerjaan Vision',
          visionJobIdSelected: 'ID Pekerjaan Vision dipilih',
        },
      },
      taskCancellation: {
        search: 'Cari berdasarkan nama permintaan atau alasan',
        criteriaBdrIdsEmpty: 'Contoh: 018a8dce',
        criteriaBdrIds: 'Masukkan ID BDR',
        inputRequestName: 'Masukkan nama permintaan.',
        inputRequestReason: 'Masukkan alasan pembatalan',
        requestName: 'Masukkan nama permintaan',
        selectPeriod: 'Pilih periode',
      },
    },
    label: {
      submission: {
        name: 'Nama pengajuan',
        validity: 'Validitas',
      },
      task: {
        sponsor: 'Mitra',
        name: 'Nama tugas',
        instruction: 'Instruksi tugas',
        category: 'Pilih kategori tugas:',
        subCategory: 'Pilih sub-kategori tugas:',
        list: {
          selectProfileListTasks: 'PROFIL:',
          selectTaskType: {
            sponsored: 'Tugas yang Disponsori',
            nonSponsored: 'Tugas yang Disponsori',
          },
        },
        create: {
          selectProfile: 'Siapa yang akan melakukan tugas ini?',
          selectTaskType: 'Jenis tugas apa yang ingin Anda buat?',
        },
        simple: {
          duration: 'Pilih perkiraan waktu untuk menyelesaikan tugas ini:',
          frequency: 'Pilih frekuensi tugas ini harus tersedia untuk BDR:',
          effectivenessCheck: 'Pemeriksaan Efektivitas',
          effectivenessId: {
            CHALLENGE: 'Masukkan ID Tantangan',
            COMBO: 'Masukkan ID Kombo',
            MONTHLY_PRODUCT_COVERAGE: 'SKU untuk validasi',
            VOLUME: 'SKU untuk validasi',
          },
          minimumQuantity: 'Jumlah minimal',
          volumeUnit: 'Unit untuk validasi',
        },
        sponsored: 'Tugas yang Disponsori',
        sponsoredPartnerLabel: 'Pilih mitra',
        survey: {
          questionary: {
            questionTitle: 'Nomor pertanyaan',
          },
        },
        photo: {
          inputVisionJobId: 'Tambahkan ID Pekerjaan Vision',
        },
      },
      taskCancellation: {
        requestName: 'Nama Permintaan',
        reason: 'Alasan',
      },
    },
    value: {
      task: {
        type: {
          SIMPLE: 'Tugas Sederhana',
          SURVEY: 'Survei',
          IMAGE_RECOGNITION: 'Foto',
        },
        category: {
          CUSTOMER_SATISFACTION: 'Kepuasan pelanggan',
          DIGITAL_EDUCATION: 'Pendidikan Digital',
          EXECUTION: 'Eksekusi',
          VALUE_CREATION: 'Penciptaan Nilai',
          PORTFOLIO_EXPANSION: 'Perluasan Portofolio',
          VOLUME_REVENUE: 'Volume & Pendapatan',
          MARKETPLACE_PORTFOLIO_EXPANSION: 'Perluasan Portofolio Pasar',
          MARKETPLACE_REVENUE: 'Pendapatan Pasar',
          EXECUTION_ABI: 'Eksekusi ABI',
          EXECUTION_MARKETPLACE: 'Pasar Eksekusi',
          MI_NEGOCIO: 'Saya Negocio',
          FINTECH: 'FinTech',
          CHALLENGE_REWARDS: 'Tantangan dan Hadiah',
          ORDER_BEES: 'Pesan di LEBAH',
          DTAAS: 'DTaaS',
          SURVEY_DATA_COLLECTION: 'Survey/Data Koleksi',
          OTHER_TASKS: 'Tugas Lainnya',
        },
        subCategory: {
          CHALLENGE: 'Tantangan',
          COMBO: 'kombo',
          MONTHLY_PRODUCT_COVERAGE: 'Cakupan',
          SHELF: 'Rak',
          COOLER: 'Lebih keren',
          POSTER: 'Poster',
          VOLUME: 'Volume',
        },
        simple: {
          frequency: {
            everyVisit: 'Setiap kunjungan',
            monthly: 'Setiap 30 hari',
          },
        },
        survey: {
          responseType: {
            numeric: 'numerik',
            freeText: 'Teks Gratis',
            checkBox: 'Multi-pilih',
            radioButton: 'Pilih tunggal',
            conditional: 'Bersyarat',
          },
        },
      },
    },
    tip: {
      task: {
        type: {
          SIMPLE:
            'Tugas sederhana tersebut merupakan tindakan yang harus dilakukan oleh BDR selama kunjungan ke POC. Tipe ini terdiri dari judul dan instruksi.',
          SURVEY:
            'Jenis survei terdiri dari beberapa pertanyaan yang harus dijawab oleh BDR selama kunjungan ke POC. Pertanyaan dapat memiliki jenis jawaban yang berbeda, seperti pilihan ganda, pilihan tunggal, teks bebas, numerik, dan boolean.',
          IMAGE_RECOGNITION:
            'Jenis tugas foto memungkinkan BDR dengan mudah mendaftarkan aset POC seperti pendingin atau memperdagangkan materi pasar dengan menggunakan kamera yang terpasang di perangkat mereka. Jenis tugas foto juga terhubung dengan kecerdasan Pengenalan Gambar yang memungkinkan otomatisasi operasi pemeriksaan efektivitas.',
        },
        simple: {
          frequency: {
            everyVisit:
              'Setiap kunjungan: Tugas akan tersedia untuk setiap kunjungan di POC. Namun, tugas ini dapat digantikan oleh tugas lain dengan prioritas tertinggi.',
            monthly:
              'Setiap 30 hari: Tugas akan tersedia untuk BDR dalam waktu 30 hari antar kunjungan.',
          },
        },
      },
    },
    error: {
      requiredField: 'Bagian ini diperlukan',
      task: {
        requiredDuration: 'Silakan pilih perkiraan waktu sebelum menyimpan',
        requiredFrequency: 'Silakan pilih frekuensi sebelum menyimpan',
        requiredCategory: 'Silakan pilih kategori tugas sebelum menyimpan',
        requiredSubCategory: 'Silakan pilih subkategori sebelum menyimpan',
        requiredEffectivenessIDs: 'Silakan masukkan ID sebelum menyimpan',
        requiredMinimumQuantity: 'Silakan ketik jumlah minimum sebelum menyimpan',
        requiredVolumeUnit: 'Silakan pilih satuan volume sebelum menyimpan',
        requiredSponsoredBy: 'Silakan pilih mitra sebelum menyimpan',
        addVisionJobId: 'Untuk menambahkan id pekerjaan vision yang dipilih, klik tombol Tambah',
      },
    },
    hint: {
      task: {
        simple: {
          effectivenessCheck: {
            minimumQuantity: 'Masukkan nilai hingga 9999',
          },
        },
      },
    },
  },
  submission: {
    noneSubmission: 'Tidak ada kiriman',
    status: {
      FINISHED: 'Selesai',
      SCHEDULED: 'Dijadwalkan',
      ACTIVE: 'Pemulihan',
      CONCLUDED: 'Selesai',
      INVALID: 'Tidak sah',
      INVALIDS: 'Tidak sah',
      INPROGRESS: 'Pengolahan',
      IN_PROGRESS: 'Pengolahan',
    },
    list: {
      table: {
        taskSubmissions: 'Pengiriman tugas',
        status: 'Status',
        validity: 'Keabsahan',
      },
      dateFormat: 'MMM hh, yyyy',
      noValidity: 'Tidak ada validitas',
    },
    submit: {
      messages: {
        error: {
          finishedErrorAccountFoundDifferent:
            'Selesai dengan kesalahan, {accountFound} dari {accountSize} berhasil.  ',
          finishedErrorAccountDuplicated:
            'Selesai dengan {accountDuplicated} dari {accountSize} digandakan, {accountSubmitted} diserahkan.',
          finishedErrorAccountInvalidAccountDuplicated:
            '{accountNotFound} tidak valid, {accountDuplicated} digandakan, {accountSubmitted} diserahkan.  ',
          invalidItems: 'Kesalahan pada file CSV, item tidak valid',
        },
        inProgress: {
          default: 'Sedang berlangsung: {submissionProgress}',
        },
        success: {
          default: 'CSV diproses dengan sukses',
        },
      },
    },
  },
  csv: {
    header: {
      accountsId: 'ID Akun',
      accountsJustified: 'dibenarkan',
      accountsAnswered: 'menjawab',
      accountsNotAnswered: 'tidak dijawab',
      accountsCancelled: 'dibatalkan',
      accountsCreated: 'dibuat',
    },
    content: {
      answerYes: 'Ya',
      answerNo: 'tidak',
    },
    footer: {
      total: 'total',
    },
  },
  taskCancellation: {
    status: {
      CANCELLATION_REQUESTED: 'Pembatalan diminta',
      PROCESSED: 'Pembatalan selesai',
      NOT_PROCESSED: 'Pembatalan tidak diproses',
      DRAFT: 'Draf',
    },
  },
  pagination: {
    pageSize: 'Menunjukkan {pageSize} item per halaman',
  },
  emptyState: {
    title: {
      noRequests: 'Belum ada permintaan',
    },
    desc: {
      noRequests: 'Permintaan Anda di masa mendatang akan ditampilkan di sini.',
    },
  },
};

export default idID;
