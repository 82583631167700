import { CategorySearch, Clipboard } from '@hexa-ui/icons';
import { useHasPermission, useSidebar } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import { PATH_OPERATIONS_PANEL, PATH_TASK_LIST } from '../../consts/route';
import { Scopes } from '../../models/scopes';
import { SidebarStyles } from './Sidebar.styles';

export const SidebarIcons = {
  Tasks: (): JSX.Element => <Clipboard />,
  OperationsPanel: (): JSX.Element => <CategorySearch />,
};

const Sidebar = (): JSX.Element => {
  const { formatMessage } = useIntl();

  const sidebarItems = [
    {
      id: 'tasks',
      title: formatMessage({ id: 'sidebar.title.pageTasks' }),
      icon: SidebarIcons.Tasks,
      path: PATH_TASK_LIST,
    },
  ];

  if (useHasPermission(Scopes.BeesForceOperationsGlobalRead)) {
    sidebarItems.push({
      id: 'operation',
      title: formatMessage({ id: 'sidebar.title.pageOperations' }),
      icon: SidebarIcons.OperationsPanel,
      path: PATH_OPERATIONS_PANEL,
    });
  }

  useSidebar({
    items: sidebarItems,
    utils: [],
  });

  return <SidebarStyles />;
};

export default Sidebar;
