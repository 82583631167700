import { Button, Error500, Paragraph } from '@hexa-ui/components';
import { DocumentTitle, useLogService } from 'admin-portal-shared-services';
import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { HideAppHeader } from '../../styles/GlobalStyles';

import { ErrorFloatingContainer, FallbackErrorContainer } from './FallbackError.styles';

const FallbackError = ({ error, resetErrorBoundary }: FallbackProps): JSX.Element => {
  const log = useLogService();

  useEffect(() => {
    log.error(error);
  }, [log, error]);

  return (
    <>
      <DocumentTitle>Something went wrong</DocumentTitle>
      <FallbackErrorContainer role="alert" data-testid="fallback-error-page">
        <Error500 headerText="Ooops! Something went wrong" />
        <ErrorFloatingContainer>
          {error && <Paragraph alignment="center">{error.message}</Paragraph>}
          <Button type="button" onClick={resetErrorBoundary} data-testid="fallback-error-button">
            Try again
          </Button>
        </ErrorFloatingContainer>
      </FallbackErrorContainer>
      <HideAppHeader />
    </>
  );
};

export default FallbackError;
