import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  DEBUG_INITIAL_IS_OPEN,
  RETRY_DELAY_MAX_MS,
  RETRY_DELAY_MIN_MS,
  RETRY_TIMES,
  STALE_TIME_MS,
} from '../../consts/query';

interface QueryProviderProps {
  children?: JSX.Element;
  client?: QueryClient;
}

const QueryProvider = ({ children, client }: QueryProviderProps): JSX.Element => {
  const queryClient =
    client ??
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: STALE_TIME_MS,
          retry: RETRY_TIMES,
          retryDelay: (attemptIndex) =>
            Math.min(RETRY_DELAY_MIN_MS ** attemptIndex, RETRY_DELAY_MAX_MS),
        },
      },
    });

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={DEBUG_INITIAL_IS_OPEN} />
    </QueryClientProvider>
  );
};

export default QueryProvider;
