import styled from 'styled-components';
import { BREAKPOINT_MOBILE, BREAKPOINT_TABLET } from '../../consts/screen';

interface StyledContainerProps {
  hasAppHeader: boolean;
  hasBreadcrumb: boolean;
}

export const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex-direction: column;
  min-height: ${({ hasAppHeader, hasBreadcrumb }) => {
    if (hasAppHeader)
      if (hasBreadcrumb) return 'calc(100vh - 184px)';
      else return 'calc(100vh - 162px)';
    else return 'calc(100vh - 56px)';
  }};
  padding: ${({ hasAppHeader }) => {
    if (hasAppHeader) return '0 1.5rem 24px 1.5rem';
    else return '24px 1.5rem 24px 1.5rem';
  }};

  @media (min-width: ${BREAKPOINT_MOBILE}) and (max-width: ${BREAKPOINT_TABLET}) {
    min-height: ${({ hasAppHeader, hasBreadcrumb }) => {
      if (hasAppHeader)
        if (hasBreadcrumb) return 'calc(100vh - 240px)';
        else return 'calc(100vh - 218px)';
      else return 'calc(100vh - 112px)';
    }};
  }

  @media (max-width: ${BREAKPOINT_MOBILE}) {
    min-height: calc(100vh - 112px);
    padding: 24px 1.5rem;
  }
`;
