import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  PATH_BASE,
  PATH_CREATE_TASK_PHOTO,
  PATH_CREATE_TASK_SIMPLE,
  PATH_CREATE_TASK_SURVEY,
  PATH_DUPLICATE_TASK_PHOTO,
  PATH_DUPLICATE_TASK_SIMPLE,
  PATH_DUPLICATE_TASK_SURVEY,
  PATH_TASK_CANCELLATION_CREATE,
  PATH_TASK_CANCELLATION_LIST,
  PATH_TASK_CANCELLATION_UPDATE,
  PATH_TASK_LIST,
  PATH_UPDATE_TASK_PHOTO,
  PATH_UPDATE_TASK_SIMPLE,
  PATH_UPDATE_TASK_SURVEY,
} from '../consts/route';
import PageLoading from '../pages/PageLoading';

const TaskList = lazy(() => import('../pages/TaskList'));
const TaskSimpleCreate = lazy(() => import('../pages/TaskSimple/TaskSimpleCreate'));
const TaskSimpleUpdate = lazy(() => import('../pages/TaskSimple/TaskSimpleUpdate'));
const TaskSimpleDuplicate = lazy(() => import('../pages/TaskSimple/TaskSimpleDuplicate'));
const TaskSurveyCreate = lazy(() => import('../pages/TaskSurvey/TaskSurveyCreate'));
const TaskSurveyUpdate = lazy(() => import('../pages/TaskSurvey/TaskSurveyUpdate'));
const TaskSurveyDuplicate = lazy(() => import('../pages/TaskSurvey/TaskSurveyDuplicate'));
const TaskPhotoCreate = lazy(() => import('../pages/TaskPhoto/TaskPhotoCreate'));
const TaskPhotoUpdate = lazy(() => import('../pages/TaskPhoto/TaskPhotoUpdate'));
const TaskPhotoDuplicate = lazy(() => import('../pages/TaskPhoto/TaskPhotoDuplicate'));
const TaskCancellationList = lazy(() => import('../pages/TaskCancellation/TaskCancellationList'));
const TaskCancellationCreate = lazy(
  () => import('../pages/TaskCancellation/TaskCancellationCreate')
);
const TaskCancellationUpdate = lazy(
  () => import('../pages/TaskCancellation/TaskCancellationUpdate')
);
const Page404 = lazy(() => import('../pages/Page404'));

const FallbackPage = <PageLoading />;

const Router = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path={PATH_BASE}
        element={
          <Suspense fallback={FallbackPage}>
            <Navigate to={PATH_TASK_LIST} />
          </Suspense>
        }
      />

      <Route
        path={PATH_TASK_LIST}
        element={
          <Suspense fallback={FallbackPage}>
            <TaskList />
          </Suspense>
        }
      />

      <Route
        path={PATH_TASK_CANCELLATION_LIST}
        element={
          <Suspense fallback={FallbackPage}>
            <TaskCancellationList />
          </Suspense>
        }
      />

      <Route
        path={PATH_TASK_CANCELLATION_CREATE}
        element={
          <Suspense fallback={FallbackPage}>
            <TaskCancellationCreate />
          </Suspense>
        }
      />

      <Route
        path={`${PATH_TASK_CANCELLATION_UPDATE({ requestId: ':id' })}`}
        element={
          <Suspense fallback={FallbackPage}>
            <TaskCancellationUpdate />
          </Suspense>
        }
      />

      <Route
        path={PATH_CREATE_TASK_SIMPLE}
        element={
          <Suspense fallback={FallbackPage}>
            <TaskSimpleCreate />
          </Suspense>
        }
      />

      <Route
        path={PATH_CREATE_TASK_SURVEY}
        element={
          <Suspense fallback={FallbackPage}>
            <TaskSurveyCreate />
          </Suspense>
        }
      />

      <Route
        path={PATH_CREATE_TASK_PHOTO}
        element={
          <Suspense fallback={FallbackPage}>
            <TaskPhotoCreate />
          </Suspense>
        }
      />

      <Route
        path={`${PATH_UPDATE_TASK_SIMPLE({ taskId: ':id' })}`}
        element={
          <Suspense fallback={FallbackPage}>
            <TaskSimpleUpdate />
          </Suspense>
        }
      />

      <Route
        path={`${PATH_UPDATE_TASK_SURVEY({ taskId: ':id' })}`}
        element={
          <Suspense fallback={FallbackPage}>
            <TaskSurveyUpdate />
          </Suspense>
        }
      />

      <Route
        path={`${PATH_UPDATE_TASK_PHOTO({ taskId: ':id' })}`}
        element={
          <Suspense fallback={FallbackPage}>
            <TaskPhotoUpdate />
          </Suspense>
        }
      />

      <Route
        path={`${PATH_DUPLICATE_TASK_SIMPLE({ taskId: ':id' })}`}
        element={
          <Suspense fallback={FallbackPage}>
            <TaskSimpleDuplicate />
          </Suspense>
        }
      />

      <Route
        path={`${PATH_DUPLICATE_TASK_SURVEY({ taskId: ':id' })}`}
        element={
          <Suspense fallback={FallbackPage}>
            <TaskSurveyDuplicate />
          </Suspense>
        }
      />

      <Route
        path={`${PATH_DUPLICATE_TASK_PHOTO({ taskId: ':id' })}`}
        element={
          <Suspense fallback={FallbackPage}>
            <TaskPhotoDuplicate />
          </Suspense>
        }
      />

      <Route
        path="*"
        element={
          <Suspense fallback={FallbackPage}>
            <Page404 />
          </Suspense>
        }
      />
    </Routes>
  );
};
export default Router;
