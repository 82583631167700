import { useQueryClient } from '@tanstack/react-query';
import { useAppHeader, UserMetadata, useUserMetadata } from 'admin-portal-shared-services';
import { useEffect } from 'react';
import { axiosInstance } from '../../api';
import { setResetTaskStates } from '../../redux/slices/taskSlice';
import { setCountry, setGroups, setUserMetadata, setVendorId } from '../../redux/slices/userSlice';
import useAppDispatch from '../useAppDispatch';

function useAppUserMetadata(): {
  mutateUserMetadata: import('swr').KeyedMutator<UserMetadata>;
  isSuccessMetadata: boolean;
  hasErrorMetadata: boolean;
  vendorId: string;
  metadata: UserMetadata;
} {
  const dispatch = useAppDispatch();
  const {
    data: metadata,
    isLoading: isLoadingMetadata,
    hasError: hasErrorMetadata,
    mutateUserMetadata,
  } = useUserMetadata();

  const isSuccessMetadata = !hasErrorMetadata && !isLoadingMetadata && !!metadata;
  const [appHeaderConfig] = useAppHeader();
  const supportedCountries = metadata?.supportedCountries as unknown as string[];
  const firstListItemVendorId = metadata?.vendors?.find((vendor) =>
    supportedCountries.some((country) => vendor.country === country)
  )?.id;

  const groups = metadata?.authorization?.groups;
  const hasVendorId = isSuccessMetadata && metadata?.vendors?.length > 0 && !!firstListItemVendorId;
  const hasTimezone = isSuccessMetadata && !!metadata?.profile?.timezone;
  const hasGroups = isSuccessMetadata && metadata?.authorization?.groups.length > 0 && groups;
  const queryClient = useQueryClient();
  const { selectedVendor, selectedCountry } = appHeaderConfig;

  useEffect(() => {
    if (!!selectedVendor) {
      axiosInstance.defaults.headers['vendorId'] = selectedVendor;
      axiosInstance.defaults.headers['country'] = selectedCountry;
      dispatch(setResetTaskStates());
      dispatch(setVendorId(selectedVendor));
      dispatch(setCountry(selectedCountry));
      queryClient.invalidateQueries();
    }
  }, [dispatch, queryClient, selectedVendor, selectedCountry]);

  useEffect(() => {
    if (isSuccessMetadata) {
      dispatch(setUserMetadata(metadata));
      if (hasTimezone) axiosInstance.defaults.headers['timezone'] = metadata?.profile?.timezone;
      if (hasGroups) dispatch(setGroups(groups));
    }
  }, [
    isSuccessMetadata,
    metadata,
    dispatch,
    hasTimezone,
    hasVendorId,
    firstListItemVendorId,
    hasGroups,
    groups,
  ]);

  return {
    isSuccessMetadata,
    mutateUserMetadata,
    hasErrorMetadata,
    vendorId: firstListItemVendorId,
    metadata,
  };
}

export default useAppUserMetadata;
