import { ImgHTMLAttributes } from 'react';
import { IconImg } from './Icon.styles';

export interface IconProps extends ImgHTMLAttributes<HTMLImageElement> {
  'data-testid'?: string;
}

const Icon = ({
  'data-testid': dataTestId = 'icon-container',
  ...rest
}: IconProps): JSX.Element => {
  return <IconImg data-testid={dataTestId} {...rest} />;
};

export default Icon;
