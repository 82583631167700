import styled from 'styled-components';
import { BREAKPOINT_MOBILE } from '../../consts/screen';

export const FallbackErrorContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 56px);
  text-align: center;

  @media (max-width: ${BREAKPOINT_MOBILE}) {
    height: calc(100vh - 112px);
  }
`;

export const ErrorFloatingContainer = styled.div`
  position: absolute;
  top: calc((100% / 2) + 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 50%;

  @media (max-width: ${BREAKPOINT_MOBILE}) {
    top: calc((100% / 2) + 160px);
  }
`;
