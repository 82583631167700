import { useHasPermission } from 'admin-portal-shared-services';
import { Scopes } from '../../models/scopes';
import { TaskTypeId } from '../../models/task';

interface TaskScopes {
  hasTaskTemplateWrite: boolean;
  hasTaskTemplateRead: boolean;
  hasTaskSubmissionWrite: boolean;
  hasTaskSubmissionRead: boolean;
}

interface TaskTypeScopes {
  [TaskTypeId.Simple]: TaskScopes;
  [TaskTypeId.Survey]: TaskScopes;
  [TaskTypeId.Photo]: TaskScopes;
}

function useHasTaskScopes(): TaskTypeScopes {
  const hasSimpleTaskTemplateRead = useHasPermission(Scopes.BeesForceAdminSimpleTaskTemplateRead);
  const hasSimpleTaskTemplateWrite = useHasPermission(Scopes.BeesForceAdminSimpleTaskTemplateWrite);
  const hasSimpleTaskSubmissionRead = useHasPermission(
    Scopes.BeesForceAdminSimpleTaskSubmissionRead
  );
  const hasSimpleTaskSubmissionWrite = useHasPermission(
    Scopes.BeesForceAdminSimpleTaskSubmissionWrite
  );

  const hasSurveyTaskTemplateRead = useHasPermission(Scopes.BeesForceAdminSurveyTaskTemplateRead);
  const hasSurveyTaskTemplateWrite = useHasPermission(Scopes.BeesForceAdminSurveyTaskTemplateWrite);
  const hasSurveyTaskSubmissionRead = useHasPermission(
    Scopes.BeesForceAdminSurveyTaskSubmissionRead
  );
  const hasSurveyTaskSubmissionWrite = useHasPermission(
    Scopes.BeesForceAdminSurveyTaskSubmissionWrite
  );

  const hasPhotoTaskTemplateRead = useHasPermission(Scopes.BeesForceAdminPhotoTaskTemplateRead);
  const hasPhotoTaskTemplateWrite = useHasPermission(Scopes.BeesForceAdminPhotoTaskTemplateWrite);
  const hasPhotoTaskSubmissionRead = useHasPermission(Scopes.BeesForceAdminPhotoTaskSubmissionRead);
  const hasPhotoTaskSubmissionWrite = useHasPermission(
    Scopes.BeesForceAdminPhotoTaskSubmissionWrite
  );

  return {
    [TaskTypeId.Simple]: {
      hasTaskTemplateRead: hasSimpleTaskTemplateRead,
      hasTaskTemplateWrite: hasSimpleTaskTemplateWrite,
      hasTaskSubmissionRead: hasSimpleTaskSubmissionRead,
      hasTaskSubmissionWrite: hasSimpleTaskSubmissionWrite,
    },
    [TaskTypeId.Survey]: {
      hasTaskTemplateRead: hasSurveyTaskTemplateRead,
      hasTaskTemplateWrite: hasSurveyTaskTemplateWrite,
      hasTaskSubmissionRead: hasSurveyTaskSubmissionRead,
      hasTaskSubmissionWrite: hasSurveyTaskSubmissionWrite,
    },
    [TaskTypeId.Photo]: {
      hasTaskTemplateRead: hasPhotoTaskTemplateRead,
      hasTaskTemplateWrite: hasPhotoTaskTemplateWrite,
      hasTaskSubmissionRead: hasPhotoTaskSubmissionRead,
      hasTaskSubmissionWrite: hasPhotoTaskSubmissionWrite,
    },
  };
}

export default useHasTaskScopes;
