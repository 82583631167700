import MessageMap from '../i18n.d';

const es419: MessageMap = {
  title: {
    pageTasks: 'Tareas',
    filterPhotoTasks: 'Tareas de foto',
    filterSimpleSurveyTasks: 'Tareas simples y encuesta',
    filterSponsoredTasks: 'Tareas Patrocinadas',
    modalSubmissionForm: 'Enviar Tarea',
    pageTaskCreateSimple: 'Nueva tarea simple',
    pageTaskUpdateSimple: 'Actualizar tarea simple',
    pageTaskDuplicateSimple: 'Duplicación de tareas simple',
    pageTaskCreateSurvey: 'Nueva encuesta',
    pageTaskUpdateSurvey: 'Actualizar encuesta',
    pageTaskDuplicateSurvey: 'Duplicación de encuestas',
    pageTaskCreatePhoto: 'Nueva foto',
    pageTaskUpdatePhoto: 'Actualizar foto',
    pageTaskDuplicatePhoto: 'Duplicación de foto',
    pageTaskCancellationList: 'Solicitudes de Cancelación de Tareas',
    pageTaskCancellationCreate: 'Abrir Solicitud de Cancelación',
    pageNotFound: 'Página no encontrada',
    pageTaskCancellationUpdate: 'Actualizar solicitud de Cancelación',
    pageLoading: 'Cargando..',
    pageError: 'Algo salió mal',
    modalDeleteTask: '¿Borrar esta tarea: "{taskName}"?',
    modalDeleteSubmission: '¿Borrar este envío: "{submissionName}"?',
    modalConfirmDefault: 'Confirmación',
    modalConfirmChangeEffectivenessIDAddMode: {
      toMANUAL: "¿Cambiar al modo 'agregar ID manualmente'?",
      toCSV: "¿Cambiar al modo 'insertar archivo CSV'?",
    },
    modalConfirmChangeSubCategory: '¿Desea cambiar la Subcategoría?',
    mandatory: 'Obligatoria?',
    formTask: 'Detalles de la tarea',
    effectivenessCheck: 'Criterio de eficacia',
    criteriaBdrIds: 'BDR IDs',
    modalSaveDraft: '¿Guardar como borrador?',
    criteriaCancellationPeriod: 'Período de Cancelación',
  },
  subtitle: {
    modalSubmissionForm: 'Cargue el archivo CSV para publicar la tarea a la audiencia definida.',
    taskCancellationRequestCriteria: 'Selecciona y completa los criterios de solicitud.',
    criteriaBdrIds: 'Agregar ID de BDR',
    enterTaskCancellationNameReason:
      'Ingresa el nombre de la solicitud y el motivo de la cancelación.',
    modalSaveDraftCancellationRequest:
      'Ingresa un nombre de solicitud y guárdalo para poder acceder a esta solicitud más adelante.',
    criteriaCancellationPeriod: 'Día, mes o período en el que la tarea permanecerá cancelada.',
  },
  table: {
    header: {
      taskSubmissions: 'Envíos de tareas',
      taskSubmissionStatus: 'Status',
      taskSubmissionValidity: 'Validez',
      taskCancellation: {
        name: 'Nombre de la solicitud',
        reason: 'Motivo',
        period: 'Período de cancelación',
        status: 'Estado',
        actions: 'Acciones',
      },
    },
  },
  sidebar: {
    title: {
      pageTasks: 'Géstion de tareas',
      pageOperations: 'Panel de operaciones',
    },
  },
  tooltip: {
    default: 'Ver este consejo',
    menu: 'Menu',
    expandTask: 'Expandir tarea',
    noPermission: 'No tiene permiso',
    categoryDeprecated: 'La categoría de tarea seleccionada ya no está disponible. Seleccione otra',
    task: {
      prioritize: 'Prioriza esta tarea para enviarla',
      category: {
        disabled: '{profile} no puede realizar tareas de {category}',
      },
      subCategory: {
        disabled: '{subCategory} no está disponible',
        disabledInCategory: '{subCategory} no está disponible en {category}',
      },
      simple: {
        effectivenessCheck: {
          categoryNeeded:
            'Es necesario seleccionar una categoría antes de agregar el criterio de eficacia',
          subCategoryDisabled: '{subCategory} solo disponible en {categories}',
          addId: 'Use Enter para agregar ID o Comas para separar valores',
          addIdManualOrCsv:
            'Use Enter para agregar ID o Coma para separar valores o envíe un CSV. Para verificar cómo se construye el archivo, descargue el modelo a continuación',
        },
      },
      sponsored: 'Nuestro algoritmo siempre prioriza las tareas patrocinadas',
      survey: {
        reorder: 'Reordenar',
      },
      photo: {
        addVisionJobIdButton: 'Introduzca o pegue un Vision Job ID para continuar',
        visionJobIdSelected:
          'Vision Job ID ya seleccionado, para añadir otro, borre el Vision Job ID actual',
      },
    },
    submission: {
      delete: 'Cancelar envío',
      downloadCsv: 'Descargar lista de PDV',
      csvFirst:
        'Puedes generar la lista de usuarios de cuenta utilizando la base de datos local o completando la lista manualmente con los usuarios de cuenta en una planilla.',
      csvSecond:
        'Luego, inserta el archivo CSV aquí. Para saber cómo se hace el archivo, descarga el modelo abajo.',
      validityFirst:
        'La vigencia es el período en el que la tarea está habilitada por los BDRs para ser respondida/ejecutada.',
      validitySecond:
        'Si la tarea tiene una duración de sólo un día, debes seleccionar solamente la fecha de inicio. ',
    },
    priorization: {
      needChange: 'Cambiar la priorización para confirmar',
      confirm: 'Confirmar',
      reorder: 'Reordenar',
      cancel: 'Cancelar',
    },
    taskCancellation: {
      viewRequest: 'Ver solicitud',
      deleteRequest: 'Eliminar solicitud',
    },
  },
  popper: {
    task: {
      duplicate: {
        reminder: {
          title: 'Recuerda:',
          message: 'Cambia el título del la tarea para poder diferenciarlo del que duplicaste.',
        },
      },
      viewOnly: {
        reminder: {
          title: 'Recuerda:',
          message: 'Esta página es solo para ver y los datos no se pueden cambiar.',
        },
      },
    },
    submission: {
      create: {
        validity:
          'La vigencia es el período en el que la tarea está habilitada por los BDRs para ser respondida/ejecutada. Si la tarea tiene una duración de sólo un día, debes seleccionar solamente la fecha de inicio. ',
        dropzoneFile:
          'Puedes generar la lista de usuarios de cuenta utilizando la base de datos local o completando la lista manualmente con los usuarios de cuenta en una planilla. Luego, inserta el archivo CSV aquí. Para saber cómo se hace el archivo, descarga el modelo abajo.',
      },
    },
  },
  toast: {
    success: {
      task: {
        create: 'Nueva tarea creada con éxito.',
        update: '!Tarea actualizada correctamente!',
        delete: '!Tarea eliminada correctamente!',
        priority: '!Prioridad actualizada correctamente!',
      },
      taskCancellation: {
        create: 'Nueva solicitud creada con éxito',
        update: 'Solicitud actualizada.',
      },
      submission: {
        create: '!Envío creado con éxito!',
        delete: '!Envío eliminado correctamente!',
      },
    },
    error: {
      multipleFilesNotAllowed: 'No se puede agregar más de un archivo',
      task: {
        create:
          '¡Ups, lo sentimos! Un error ocurrió al enviar tu tarea. Por favor, intenta nuevamente.',
        update:
          'Se produjo un error al actualizar la Tarea. Por favor, inténtelo de nuevo más tarde.',
        delete:
          'Se produjo un error al eliminar la Tarea. Por favor, inténtelo de nuevo más tarde.',
        getTaskData:
          'Se produjo un error al obtener los datos de la Tarea. Por favor, inténtelo de nuevo más tarde.',
        effectivenessCheck: {
          idAlreadyAdded: '¡Esta ID ya ha sido agregada!',
        },
        priority:
          'Se produjo un error al actualizar la Prioridad. Por favor, inténtelo de nuevo más tarde.',
        invalidFrequency: 'La frecuencia no es válida, seleccione una de las opciones disponibles.',
      },
      taskCancellation: {
        getRequestData:
          'Ocurrió un error al obtener los datos de la solicitud. Por favor, inténtelo de nuevo más tarde.',
        create:
          '¡Ups, lo sentimos! Un error ocurrió al enviar su solicitud. Por favor, intenta nuevamente.',
        update:
          'Ocurrió un error al actualizar la Solicitud. Por favor, inténtelo de nuevo más tarde.',
      },
      submission: {
        create:
          '¡Ups, lo sentimos! Un error ocurrió al enviar su Envío. Por favor, intenta nuevamente.',
        delete:
          'Se produjo un error al eliminar lo Envío. Por favor, inténtelo de nuevo más tarde.',
      },
    },
    warning: {
      task: {
        requiredField:
          'Campos obligatorios no rellenados! Por favor, compruebe el formulario antes de enviarlo de nuevo.',
      },
    },
  },
  comboBox: {
    noOptions: 'No se encontraron opiniones',
  },
  message: {
    desc: {
      deleteTask: 'Si lo haces, todos los envíos relacionados serán eliminiados.',
      deleteSubmission: 'Si lo haces, todos los envíos relacionados serán eliminiados.',
      modalConfirmDefault: '¿Estás seguro de que quieres hacer esto?',
      modalConfirmChangeEffectivenessIDAddMode: {
        toMANUAL: 'Si lo hace, el archivo CSV se borrará definitivamente.',
        toCSV: 'Si lo hace, todas las identificaciones agregadas se borrarán definitivamente..',
      },
      modalConfirmChangeSubCategory:
        'Si lo hace, la información de la Subcategoría seleccionada previamente se borrará de forma permanente.',
      dropzoneCsvIds: 'Coloque un archivo .CSV con los ID o',
      categoryPhoto: '*Para tareas fotográficas, ejecución es la única categoría aceptada.',
      criteriaBdrIdsQuantity: '{quantity} ID de BDR añadidos',
    },
    loading: {
      default: 'Cargando...',
      waitAMoment: 'Espere un momento...',
      validating: 'Validando, espera un momento...',
    },
    success: {
      fileValidate: '¡Archivo validado!',
      priorityUpdated: 'Prioridad actualizada',
      validTaskCategory: 'Categoría de tarea válida seleccionada',
    },
    error: {
      default: 'Ooops! Lo siento, algo salió mal',
      request: 'Se produjo un error con su solicitud. Por favor, inténtelo de nuevo más tarde.',
      notFound: 'Mmm… Parece que esta página se ha ido',
      notFoundSuggestion: 'Parece que esta página no se encontró o no existe.',
      userWithoutGroups: 'Su usuario no tiene permisos de los grupos',
      userWithoutVendorId: 'Su usuario no tiene un ID de empresa',
      userWithoutCountry: 'Su usuario no tiene un País',
      userWithoutCountryAndVendor: 'Su usuario no tiene un País y um ID de empresa',
      requestUserInfo:
        'Se produjo un error con su solicitud de sus datos de usuario. Por favor, inténtelo de nuevo más tarde.',
      getTaskOptions: '¡Perdón! Opciones no encontradas. Inténtelo de nuevo haciendo',
      optimizelyNotLoaded:
        '¡Perdón!. La conexión con el servicio de optimización no ha tenido éxito. inténtelo de nuevo más tarde.',
      withoutResults: '¡Perdon! No se encontraron resultados',
      searchEmptyList: '¡Perdon! No se encontraron resultados para',
      searchEmptyListSuggestion: 'Comprueba que has escrito correctamente las palabras.',
      tasksNotFound: '¡Ups! No hay tareas para enumerar.',
      tasksNotFoundSuggestion: 'Intente cambiar el filtro seleccionado o crear una nueva tarea.',
      vendorUndefinedTaskList: 'Seleccione un país y una empresa disponibles.',
      emptyFile: 'El archivo esta vacio',
      dropzoneEmpty: 'No se han insertado archivos. ',
      dropzoneEmptySuggestion: '¡Intenta insertar algo!',
      invalidCsvTask: 'Inserte un archivo .CSV válido antes de enviar la tarea. ',
      fileWrongExtension: 'Extensión de archivo incorrecta',
      fileMoreThanOneColumn: 'El archivo tiene más de una columna',
      fileHaveDuplicatedAccounts:
        'El archivo contiene cuentas duplicadas, revisa e intenta de nuevo.',
      fileMoreThanTwoColumn: 'El archivo tiene más de dos columnas',
      fileWrongColumnName: 'Nombre de columna incorrecto',
      multipleAnswer: 'Hay una tarea / pregunta con menos de dos respuestas definidas',
      duplicatedAnswer: 'Hay una tarea / pregunta con una respuesta duplicada',
      visionJobIdNotValid: 'ERROR: Vision Job ID seleccionado inválido',
      selectVisionJobIdValid: 'Por favor, escriba o pegue otro vision Job ID',
      duplicatedBdrId: 'ID de BDR ya ingresado. Prueba otro.',
      invalidBdrId: 'ID de BDR no encontrado. Entra otro.',
      invalidSku: 'SKU no encontrado. Entra otro.',
      someSkuInvalid:
        'Algunos SKU no son válidos, descargue el informe para obtener información detallada.',
      allSkuInvalid:
        'Todos los SKU enviados no son válidos, descargue el informe para obtener información detallada.',
      hectoliterVolumeUnitInvalid:
        'La unidad debe ser hectolitros. Revisa la información y asegúrate de usar la plantilla anterior.',
      packVolumeUnitInvalid:
        'La unidad debe ser paquete. Revisa la información y asegúrate de usar la plantilla anterior.',
      amountDifferentThanInteger:
        'El campo de monto requiere números enteros del 1 al 9999. Revisa y vuelve a intentarlo.',
      invalidVolumeAmount:
        'Las tareas por volumen requieren una plantilla CSV adecuada que contenga identificaciones de cuenta y cantidades. Revisa y vuelve a intentarlo.',
    },
    alert: {
      setTaskPriority: 'Establecer la prioridad de esta tarea',
      deprecatedTaskCategory:
        'La categoria de tarea seleccionaa ya no está disponible. Por favor, seleccione otra.',
      hectoliterSubmission:
        'La unidad para la validación establecida en esta tarea son los hectolitros.',
      packSubmission: 'La unidad para la validación establecida en esta tarea son los paquetes.',
    },
    info: {
      volumeUnit:
        'El monto de la validación se definirá en el envío de la tarea junto con la audiencia.',
    },
  },
  button: {
    newTask: 'Nueva tarea',
    newSubmission: 'Enviar',
    openCancellationRequest: 'Abrir solicitud',
    updateCancellationRequest: 'Solicitud de actualización',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    apply: 'Aplicar',
    saveChanges: 'Guardar cambios',
    tryAgain: 'Inténtalo de nuevo',
    backToHome: 'Volver a la página de inicio',
    tryAgainTaskOptions: 'clic aquí.',
    createTask: 'Crear Tarea',
    saveCreateTask: 'Guardar nueva tarea',
    addEffectivenessCheck: 'Agregar verificación de efectividad',
    clearSearch: 'Limpiar búsqueda',
    clearSearchFilters: 'Limpiar filtros',
    clearFilters: 'Limpiar todo',
    viewOnlyTask: 'Ver',
    viewAndEditTask: 'Ver y editar',
    duplicateTask: 'Duplicar',
    deleteTask: 'Eliminar',
    deleteSubmission: 'Eliminar envío',
    send: 'Enviar',
    downloadModel: 'Descargar modelo',
    browseFiles: 'Navega en tu dispositivo',
    newQuestion: 'Agregar nueva pregunta',
    tabs: {
      submit: 'Envíos',
      priority: 'Prioridades',
    },
    jumpToQuestion: 'Ir a la pregunta',
    endQuestionary: 'Finalizar el cuestionario',
    addNewAnswer: 'Añadir nueva respuesta',
    addVisionJobId: 'Incluir',
    filter: 'Filtros',
    openRequest: 'Abrir solicitud',
    cancelTask: 'Cancelar tarea',
    downloadCSV: 'Descargar CSV',
    discard: 'Desechar',
    save: 'Guardar',
    downloadReport: 'Descargar Informe',
  },
  formField: {
    placeholder: {
      submission: {
        name: 'Ingrese el nombre del envío',
        validity: 'Seleccione el período de validez',
        drop: 'Suelta aquí un archivo .CSV con los Account IDs',
      },
      task: {
        search: 'Buscar tarea',
        name: 'Digita un nombre para la encuesta (solo visible en el Admin Portal)',
        instruction: 'Escriba una instrucción para la tarea (se usará en BEES•Force por los BDR)',
        category: 'Seleccione una categoría',
        simple: {
          effectivenessCheck: {
            main: 'Si no se agrega el criterio de eficacia, la tarea será validada en el momento en que el {profile} la complete.',
            id: {
              MONTHLY_PRODUCT_COVERAGE: 'Agregar SKUs de Cobertura',
              COMBO: 'Agregar ID do Combo',
              CHALLENGE: 'Agregar ID do Desafío',
              VOLUME: 'Agregar SKUs de Volumen',
            },
            minimumQuantity: 'Introduzca la cantidad mínima',
            volumeUnit: 'Seleccionar unidad',
          },
        },
        survey: {
          inputAnswerFreeText: 'Respuesta BDR (Texto libre)',
          inputAnswerNumeric: 'Respuesta BDR (Numérico)',
          questionTitle: 'Escribe la pregunta',
          selectValue: 'Seleccione un valor',
          optionValue: 'introduzca la respuesta',
          selectCondition: 'Seleccionar condición',
        },
        photo: {
          inputVisionJobId: 'Introduzca o pegue el Vision Job ID',
          visionJobIdSelected: 'Vision Job ID seleccionado',
        },
      },
      taskCancellation: {
        search: 'Buscar por nombre de solicitud o motivo',
        criteriaBdrIdsEmpty: 'Ejemplo: 018a8dce',
        criteriaBdrIds: 'Ingrese ID BDR',
        inputRequestName: 'Ingresa un nombre de solicitud.',
        inputRequestReason: 'Ingresa el motivo de la cancelación',
        requestName: 'Ingrese el nombre de la solicitud',
        selectPeriod: 'Seleccionar período',
      },
    },
    label: {
      submission: {
        name: 'Nombre de envío',
        validity: 'Validez',
      },
      task: {
        sponsor: 'Socio',
        name: 'Nombre de la tarea',
        instruction: 'Instrucción de tarea',
        category: 'Selecciona una categoría para la tarea:',
        subCategory: 'Elige una subcategoría:',
        list: {
          selectProfileListTasks: 'PERFIL:',
          selectTaskType: {
            label: 'TIPO DE TAREA:',
            sponsored: 'Patrocinada',
            nonSponsored: 'No Patrocinada',
          },
        },
        create: {
          selectProfile: '¿Quién realizará esta tarea?',
          selectTaskType: '¿Qué tipo de tarea quieres crear?',
        },
        simple: {
          duration: 'Seleccione una estimación de tiempo para completar la tarea:',
          frequency:
            'Seleccione una frecuencia que esta tarea debería estar disponible para el BDR:',
          effectivenessCheck: 'Criterio de eficacia',
          effectivenessId: {
            CHALLENGE: 'Agregar ID do Desafío',
            COMBO: 'Agregar ID do Combo',
            MONTHLY_PRODUCT_COVERAGE: 'SKU para validación',
            VOLUME: 'SKU para validación',
          },
          minimumQuantity: 'Cantidad mínima',
          volumeUnit: 'Unidad de validación',
        },
        sponsored: 'Tarea Patrocinada',
        sponsoredPartnerLabel: 'Seleccione al socio',
        survey: {
          questionary: {
            questionTitle: 'Pregunta numero',
          },
        },
        photo: {
          inputVisionJobId: 'Incluir Vision Job ID',
        },
      },
      taskCancellation: {
        requestName: 'Nombre de la Solicitud',
        reason: 'Motivo',
      },
    },
    value: {
      task: {
        type: {
          SIMPLE: 'Tarea Simple',
          SURVEY: 'Encuesta',
          IMAGE_RECOGNITION: 'Foto',
        },
        category: {
          CUSTOMER_SATISFACTION: 'Satisfacción del Cliente',
          DIGITAL_EDUCATION: 'Educación Digital',
          EXECUTION: 'Ejecución',
          VALUE_CREATION: 'Creación de Valor',
          PORTFOLIO_EXPANSION: 'Expansion de Portfolio',
          VOLUME_REVENUE: 'Volumen y Facturación',
          MARKETPLACE_PORTFOLIO_EXPANSION: 'Expansión de Portfolio Marketplace',
          MARKETPLACE_REVENUE: 'Facturación Marketplace',
          EXECUTION_ABI: 'Ejecución ABI',
          EXECUTION_MARKETPLACE: 'Ejecución Marketplace',
          MI_NEGOCIO: 'Mi Negocio',
          FINTECH: 'FinTech',
          CHALLENGE_REWARDS: 'Desafíos y Recompensas',
          ORDER_BEES: 'Ordenes en BEES',
          DTAAS: 'DTaaS',
          SURVEY_DATA_COLLECTION: 'Encuesta/Recopilación de Datos',
          OTHER_TASKS: 'Otras Tareas',
        },
        subCategory: {
          CHALLENGE: 'Desafío',
          COMBO: 'Combo',
          MONTHLY_PRODUCT_COVERAGE: 'Cobertura',
          SHELF: 'Estantes',
          COOLER: 'Refrigeradores',
          POSTER: 'Poster',
          VOLUME: 'Volumen',
        },
        simple: {
          frequency: {
            everyVisit: 'En cada visita',
            monthly: 'Cada 30 días',
          },
        },
        survey: {
          responseType: {
            numeric: 'Numérico',
            freeText: 'Texto libre',
            checkBox: 'Selección múltiple',
            radioButton: 'Selección única',
            conditional: 'Condicional',
          },
        },
      },
    },
    tip: {
      task: {
        type: {
          SIMPLE:
            'La tarea simple es una acción para que los BDRs ejecuten durante la visita al PDV. Este tipo de tarea consiste en un título y una instrucción.',
          SURVEY:
            'La encuesta consiste en varias preguntas para que los BDRs respondan durante la visita al PDV. Las preguntas pueden tener diferentes tipos de respuestas, como multiple choice, texto libre, númerico o Sí o No.',
          IMAGE_RECOGNITION:
            'El tipo de tarea fotográfica permite a los BDR registrar fácilmente los activos de PDV como refrigeradores o materiales del mercado comercial mediante el uso de la cámara incorporada en sus dispositivos. El tipo de tarea fotográfica también está conectado a la inteligencia de reconocimiento de imágenes que permite automatizar la operación de verificación de efectividad.',
        },
        simple: {
          frequency: {
            everyVisit:
              'En cada visita: La tarea estará disponible en cada visita al POC. Con todo, esta plantilla de tarea se puede reemplazar por otra tarea con mayor prioridad.',
            monthly:
              'Cada 30 días: La tarea estará disponible para BDR dentro de los 30 días entre visitas.',
          },
        },
      },
    },
    error: {
      requiredField: 'Este campo es obligatorio',
      task: {
        requiredDuration: 'Seleccione una opción de estimación para la tarea antes de guardar',
        requiredFrequency: 'Por favor, elija una frecuencia antes de guardar',
        requiredCategory: 'Por favor, elija una categoría de tarea antes de guardar',
        requiredSubCategory: 'Por favor, elija una subcategoría antes de guardar',
        requiredEffectivenessIDs: 'Por favor, agregue los IDs antes de guardar',
        requiredMinimumQuantity: 'Por favor, ingrese la cantidad mínima antes de guardar',
        requiredVolumeUnit: 'Por favor, elija una unidad de volumen antes de guardar',
        requiredSponsoredBy: 'Por favor, ingrese al socio antes de salvar',
        addVisionJobId: 'Para incluir el visionjobid seleccionado, haga clic en el botón "incluir"',
      },
    },
    hint: {
      task: {
        simple: {
          effectivenessCheck: {
            minimumQuantity: 'Introduzca un valor hasta 9999',
          },
        },
      },
    },
  },
  submission: {
    noneSubmission: 'Ninguno activo',
    status: {
      FINISHED: 'Terminado',
      SCHEDULED: 'Programado',
      ACTIVE: 'Activo',
      CONCLUDED: 'Terminado',
      INVALID: 'Inválido',
      INVALIDS: 'Inválido',
      INPROGRESS: 'Procesando',
      IN_PROGRESS: 'Procesando',
    },
    list: {
      table: {
        taskSubmissions: 'Envíos de tareas',
        status: 'Status',
        validity: 'Validez',
      },
      ddateFormat: 'dd/MM/yyyy',
      noValidity: 'Sin validez',
    },
    submit: {
      messages: {
        error: {
          finishedErrorAccountFoundDifferent:
            'Finalizó con errores, se procesaron {accountFound} de {accountSize}',
          finishedErrorAccountDuplicated:
            'Finalizó con {accountDuplicated} de {accountSize} duplicados, procesaron {accountSubmitted}',
          finishedErrorAccountInvalidAccountDuplicated:
            '{accountNotFound} inválidos, {accountDuplicated} duplicados, procesaron {accountSubmitted}',
          invalidItems: 'Error de archivo CSV, elementos no válidos',
        },
        inProgress: {
          default: 'Cargando CSV: {submissionProgress}',
        },
        success: {
          default: 'CSV procesado con éxito',
        },
      },
    },
  },
  csv: {
    header: {
      accountsId: 'accountId',
      accountsJustified: 'justificado',
      accountsAnswered: 'contestada',
      accountsNotAnswered: 'no contestado',
      accountsCancelled: 'cancelado',
      accountsCreated: 'creado',
    },
    content: {
      answerYes: 'sí',
      answerNo: 'no',
    },
    footer: {
      total: 'total',
    },
  },
  taskCancellation: {
    status: {
      CANCELLATION_REQUESTED: 'Solicitud de cancelación',
      PROCESSED: 'Cancelación completada',
      NOT_PROCESSED: 'Cancelación no procesada',
      DRAFT: 'Borrador',
    },
  },
  pagination: {
    pageSize: 'Mostrar {pageSize} elementos por página',
  },
  emptyState: {
    title: {
      noRequests: 'Aún no hay solicitudes',
    },
    desc: {
      noRequests: 'Tus solicitudes futuras se mostrarán aquí.',
    },
  },
};

export default es419;
