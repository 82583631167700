import { usePreferredLanguage } from 'admin-portal-shared-services';
import { ReactNode } from 'react';
import { IntlProvider as NativeProvider } from 'react-intl';
import MessageMap from '../../i18n/i18n.d';
import { flattenObject } from '../../utils/objects';

import enUS from '../../i18n/languages/en-US';
import es419 from '../../i18n/languages/es-419';
import idID from '../../i18n/languages/id-ID';
import ptBR from '../../i18n/languages/pt-BR';

const DEFAULT_LANGUAGE = 'en-US';
type IIntlProvider = {
  children: ReactNode;
};

export const mergeMessages = (messagesInput: MessageMap, selectedLocale: string): any => {
  const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE]);
  const localeMessages = flattenObject(messagesInput[selectedLocale]);
  return { ...defaultMessages, ...localeMessages };
};

export const messages: { [language: string]: MessageMap } = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-419': es419,
  'id-ID': idID,
};

export function IntlProvider({ children }: Readonly<IIntlProvider>): JSX.Element {
  const preferredLanguage = usePreferredLanguage();

  const mergedMessages = mergeMessages(messages, preferredLanguage);

  return (
    <NativeProvider
      locale={preferredLanguage}
      defaultLocale={DEFAULT_LANGUAGE}
      messages={mergedMessages}
    >
      {children}
    </NativeProvider>
  );
}

export default IntlProvider;
