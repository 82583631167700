import { ProfileId } from './user';

export enum TaskCancellationStatus {
  CancellationRequested = 'CANCELLATION_REQUESTED',
  Processed = 'PROCESSED',
  NotProcessed = 'NOT_PROCESSED',
  Draft = 'DRAFT',
}

export enum ProjectionGetTaskCancellationRequest {
  Full = 'FULL',
  Csv = 'CSV',
}

export enum TaskCancellationFormField {
  Id = '_id',
  Name = 'name',
  Reason = 'reason',
  Status = 'status',
  Parameters = 'parameters',
  UpdatedBy = 'updatedBy',
  UpdatedAt = 'updatedAt',
  Period = 'period',
  BdrList = 'bdrList',
}

export enum TaskCancellationDateRange {
  DateRange = 'dateRange',
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export enum TaskCancellationBdrList {
  Id = 'id',
}

export interface GetTaskCancellationRequests {
  page: number;
  pageSize: number;
  nameOrReason?: string;
  signal?: AbortSignal;
}

export interface GetTaskCancellationRequestsResponse {
  content: TaskCancellationRequest[];
  pagination: { page: number; pageSize: number; totalElements: number; totalPages: number };
}

export interface GetTaskCancellationRequest {
  requestId: string;
  projection?: ProjectionGetTaskCancellationRequest;
  period: string;
  signal?: AbortSignal;
}

export interface TaskCancellationRequest {
  _id: string;
  vendorId: string;
  name: string;
  country: string;
  reason: string;
  status: TaskCancellationStatus;
  parameters: {
    dateRange: {
      startDate: string;
      endDate: string;
    };
  };
  updatedBy: string;
  updatedAt: string;
  period: string;
  accountList: {
    list: Account[];
  };
  bdrList: {
    list: Bdr[];
  };
  taskList: {
    list: Task[];
  };
}

export interface PayloadTaskCancellation {
  _id: string;
  name: string;
  reason: string;
  status: string;
  updatedBy: string;
  updatedAt?: string;
  parameters?: {
    dateRange: DateRange;
  };
  period: string;
  bdrList?: Bdr[];
}

export interface UpdateTaskCancellation {
  requestId: string;
  body: PayloadTaskCancellation;
}

export interface Bdr {
  id: string;
}

export interface Task {
  id: string;
}

export interface Account {
  id: string;
}

export interface DateRange {
  startDate?: string;
  endDate?: string;
}

export interface CreateTaskCancellation {
  body: PayloadTaskCancellation;
}

export interface GetBdrAllInfo {
  bdrId: string;
  signal?: AbortSignal;
}

export interface BdrInfo {
  bdrId: string;
  distributionCenterId: number;
  supervisorId: number;
  isActive: boolean;
  sectorId: string;
  accounts: {
    accountId: string;
    contractId: string;
  }[];
  updatedBy: string;
  updatedAt: string;
  profile: ProfileId;
  vendorId: string;
}

export interface GetCancellationCurrentPeriod {
  signal?: AbortSignal;
}

export interface CancellationCurrentPeriod {
  period: string;
}
