import { useDecision } from '@optimizely/react-sdk';
import { useAppHeader } from 'admin-portal-shared-services';
import { useCallback, useEffect } from 'react';
import { OPTIMIZELY_KEY_MULTIPLE_PROFILES } from '../../consts/optimizely';
import { DEFAULT_CATEGORIES_DEPRECATED } from '../../consts/task';
import { DEFAULT_PROFILE } from '../../consts/user';
import { CategoryDeprecated, CategoryId, TaskState } from '../../models/task';
import { Profile, ProfileOptimizelyEntry } from '../../models/user';
import { setAvailableCategoriesDeprecated } from '../../redux/slices/taskSlice';
import { setAvailableProfiles, setProfile } from '../../redux/slices/userSlice';
import useAppDispatch from '../useAppDispatch';
import useAppSelector from '../useAppSelector';

interface UseMultiProfileFlagProps {
  vendorId: string | undefined;
}

function useMultiProfileFlag({ vendorId }: UseMultiProfileFlagProps): {
  isSuccessMultiProfileFlag: boolean;
  isMultiProfileFlagTimeout: boolean;
} {
  const dispatch = useAppDispatch();
  const [multiProfileFlag, isMultiProfileFlagReady, isMultiProfileFlagTimeout] = useDecision(
    OPTIMIZELY_KEY_MULTIPLE_PROFILES
  );
  const [appHeaderConfig] = useAppHeader();
  const { availableTaskTypes } = useAppSelector<TaskState>((state) => state.task);
  const isSuccessMultiProfileFlag =
    !isMultiProfileFlagTimeout && isMultiProfileFlagReady && !!multiProfileFlag;

  const extractAvailableProfilesCategories = useCallback(
    (currentVendorId: string, profilesFlag: unknown) => {
      const availableProfiles: Profile[] = [];
      const availableCategories: CategoryDeprecated[] = [];

      if (!!profilesFlag) {
        const profilesEntries = Object.entries(profilesFlag);

        profilesEntries.forEach(([profileId, profileVendors]: ProfileOptimizelyEntry) => {
          const isVendorEnabled = !!profileVendors[currentVendorId];
          if (isVendorEnabled) {
            availableProfiles.push({
              id: profileId,
              title: profileVendors[currentVendorId].title,
            });

            const hasCategoriesCurrentVendor =
              profileVendors[currentVendorId]?.categories?.replace(' ', '').length > 0;

            if (hasCategoriesCurrentVendor) {
              const categoriesCurrentVendor = profileVendors[currentVendorId].categories
                .replace(' ', '')
                .split(',');

              categoriesCurrentVendor.forEach((category: CategoryId) => {
                const categoryIndex = availableCategories.findIndex(
                  (availableCategory) => availableCategory.id === category
                );
                const hasAvailableCategory = categoryIndex !== -1;

                if (hasAvailableCategory) {
                  availableCategories[categoryIndex].profiles.push(profileId);
                } else {
                  const availableTaskTypesCurrentProfile = availableTaskTypes
                    .filter((taskType) => taskType.categories.includes(category))
                    .map((taskType) => taskType.id);

                  availableCategories.push({
                    id: category,
                    profiles: [profileId],
                    taskTypes: availableTaskTypesCurrentProfile,
                  });
                }
              });
            }
          }
        });
      }
      return { availableProfiles, availableCategories };
    },
    [availableTaskTypes]
  );

  useEffect(() => {
    if (vendorId && isSuccessMultiProfileFlag) {
      const profilesFlag = multiProfileFlag.variables?.profiles;
      const { availableProfiles, availableCategories } = extractAvailableProfilesCategories(
        appHeaderConfig.selectedVendor ?? vendorId,
        profilesFlag
      );
      const hasAvailableProfiles = availableProfiles.length > 0;
      const hasAvailableCategories = availableCategories.length > 0;

      if (multiProfileFlag.enabled && hasAvailableProfiles && hasAvailableCategories) {
        dispatch(setAvailableProfiles(availableProfiles));
        dispatch(setAvailableCategoriesDeprecated(availableCategories));
      } else {
        dispatch(setAvailableProfiles([DEFAULT_PROFILE]));
        dispatch(setAvailableCategoriesDeprecated(DEFAULT_CATEGORIES_DEPRECATED));
      }

      dispatch(setProfile(DEFAULT_PROFILE));
    }
  }, [
    dispatch,
    multiProfileFlag.enabled,
    multiProfileFlag.variables?.profiles,
    vendorId,
    appHeaderConfig.selectedVendor,
    isSuccessMultiProfileFlag,
    extractAvailableProfilesCategories,
  ]);

  return {
    isSuccessMultiProfileFlag,
    isMultiProfileFlagTimeout,
  };
}

export default useMultiProfileFlag;
