import { useDecision } from '@optimizely/react-sdk';
import { useCallback, useEffect } from 'react';
import { OPTIMIZELY_KEY_TASK_CATEGORIES } from '../../consts/optimizely';
import { DEFAULT_CATEGORIES } from '../../consts/task';
import { Category, CategoryOptimizelyEntry } from '../../models/task';
import { setAvailableCategories } from '../../redux/slices/taskSlice';
import useAppDispatch from '../useAppDispatch';

function useTaskCategoriesFlag(): {
  isSuccessTaskCategoriesFlag: boolean;
  isTaskCategoriesFlagTimeout: boolean;
} {
  const [taskCategories, isTaskCategoriesReady, isTaskCategoriesFlagTimeout] = useDecision(
    OPTIMIZELY_KEY_TASK_CATEGORIES
  );
  const isSuccessTaskCategoriesFlag =
    !isTaskCategoriesFlagTimeout && isTaskCategoriesReady && !!taskCategories;
  const dispatch = useAppDispatch();

  const getAvailableCategories = useCallback(() => {
    const categoriesFlag = taskCategories.variables.categories;

    const availableCategories: Category[] = [];

    if (!!categoriesFlag) {
      const categoriesEntries = Object.entries(categoriesFlag);

      categoriesEntries.forEach(([categoryId, categoryValue]: CategoryOptimizelyEntry) => {
        availableCategories.push({
          id: categoryId,
          title: categoryValue.title,
        });
      });
    }

    return { availableCategories };
  }, [taskCategories]);

  useEffect(() => {
    if (isTaskCategoriesReady) {
      const { availableCategories } = getAvailableCategories();
      const hasAvailableCategories = availableCategories.length > 0;

      if (hasAvailableCategories) {
        dispatch(setAvailableCategories(availableCategories));
      } else {
        dispatch(setAvailableCategories(DEFAULT_CATEGORIES));
      }
    }
  }, [taskCategories, isTaskCategoriesReady, dispatch, getAvailableCategories]);
  return {
    isSuccessTaskCategoriesFlag,
    isTaskCategoriesFlagTimeout,
  };
}

export default useTaskCategoriesFlag;
