/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */

// The flattenObject method will transform deep objects into flat
// Eg:
// const object = flattenObject({
//     TEST: {
//         TEST_TRANSLATE: 'Translate',
//         TEST_ARRAY: [],
//     },
// });
//
// const result = { 'TEST.TEST_TRANSLATE': 'Translate', 'TEST.TEST_ARRAY': [] }
export const flattenObject = (object: any): any => {
  const result: { [key: string]: any } = {};

  for (const property in object) {
    const isObject = typeof object[property] === 'object';
    const isNotNull = object[property] !== null;
    const isEmptyArray = Array.isArray(object[property]) && object[property].length === 0;

    if (isObject && isNotNull && !isEmptyArray) {
      const flatObject = flattenObject(object[property]);
      for (const propertyFlatObject in flatObject) {
        const key = `${property}.${propertyFlatObject}`;
        result[key] = flatObject[propertyFlatObject];
      }
    } else {
      result[property] = object[property];
    }
  }

  return result;
};
/* eslint-enable */
