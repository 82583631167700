import { SubmissionStatusCount } from './submission';
import { ProfileId } from './user';

export enum TaskTypeId {
  Simple = 'SIMPLE',
  Survey = 'SURVEY',
  Photo = 'IMAGE_RECOGNITION',
}

export enum DurationId {
  Fast = 'FAST',
  Medium = 'MEDIUM',
  Long = 'LONG',
  VeryLong = 'VERY_LONG',
  VeryFast = 'VERY_FAST',
  Empty = '',
}

export enum CategoryId {
  CustomerSatisfaction = 'CUSTOMER_SATISFACTION',
  DigitalEducation = 'DIGITAL_EDUCATION',
  Execution = 'EXECUTION',
  ValueCreation = 'VALUE_CREATION',
  PortfolioExpansion = 'PORTFOLIO_EXPANSION',
  VolumeRevenue = 'VOLUME_REVENUE',
  MarketplacePortfolioExpansion = 'MARKETPLACE_PORTFOLIO_EXPANSION',
  MarketplaceRevenue = 'MARKETPLACE_REVENUE',
  ExecutionAbi = 'EXECUTION_ABI',
  ExecutionMarketplace = 'EXECUTION_MARKETPLACE',
  MiNegocio = 'MI_NEGOCIO',
  FinTech = 'FINTECH',
  ChallengeAndRewards = 'CHALLENGE_REWARDS',
  OrderOnBEES = 'ORDER_BEES',
  DTaaS = 'DTAAS',
  SurveyDataCollection = 'SURVEY_DATA_COLLECTION',
  OtherTasks = 'OTHER_TASKS',
  Empty = '',
}

export enum SubTypeId {
  Challenge = 'CHALLENGE',
  Combo = 'COMBO',
  Coverage = 'MONTHLY_PRODUCT_COVERAGE',
  Volume = 'VOLUME',
  Empty = '',
}

export enum SubTypeIdPhoto {
  ImageRecognition = 'IMAGE_RECOGNITION',
}

export enum ImageRecognitionBucketId {
  Shelf = 'SHELF',
  Poster = 'POSTER',
  Cooler = 'COOLER',
  None = 'NONE',
  Empty = '',
}

export enum FormTypeId {
  Create = 'CREATE',
  Update = 'UPDATE',
  Duplicate = 'DUPLICATE',
}

export enum FrequencyId {
  EveryVisit = 'EVERY_VISIT',
  Monthly = 'MONTHLY',
  Effective = 'EVERY_VISIT_UNTIL_COMPLETED_OR_EFFECTIVE',
  Empty = '',
}

export enum SurveyQuestionResponseId {
  SingleSelect = 'SINGLESELECTION',
  FreeText = 'FREETEXT',
  Conditional = 'CONDITIONAL',
  Numeric = 'NUMERIC',
  MultipleSelect = 'MULTIPLESELECTION',
  Empty = '',
}

export enum AddMethod {
  Manual = 'MANUAL',
  Csv = 'CSV',
}

export enum TaskFormField {
  Name = 'name',
  TemplateTitle = 'templateTitle',
  Profile = 'profile',
  IsSponsored = 'isSponsored',
  SponsoredBy = 'sponsoredBy',
  SponsoredById = 'sponsoredById',
  Duration = 'duration',
  Frequency = 'frequency',
  Category = 'category',
}

export enum TaskFormFieldSimple {
  ConfigEffectiveness = 'configTaskEffectiveness',
  SubType = 'subType',
}

export enum TaskFormFieldSurvey {
  Subtasks = 'subTasks',
}

export enum TaskFormFieldPhoto {
  ImageRecognitionBucket = 'imageRecognitionBucket',
  ConfigEffectiveness = 'configTaskEffectiveness',
}

export enum EffectivenessSimpleFormField {
  SkuList = 'skuList',
  ChallengeId = 'challengeId',
  MinimumQuantity = 'minimumQuantity',
  VolumeUnit = 'volumeUnit',
}

export enum EffectivenessPhotoFormField {
  SubType = 'subType',
  VisionConfigId = 'visionConfigId',
}

export enum SubTaskOptionFormField {
  Id = 'id',
  Value = 'value',
  Jump = 'jump',
}

export enum SubTaskConditionalOptionFormField {
  Id = 'id',
  Position = 'position',
}

export enum SurveyQuestionaryFormField {
  Id = 'id',
  Title = 'title',
  IsRequired = 'isRequired',
  ResponseType = 'responseType',
  Options = 'options',
}

export enum SurveyQuestionaryError {
  HasErrorQuantity = 'field must have 2 items',
  HasDuplicatedFields = 'duplicated fields are not allowed',
}

export enum OriginScreenName {
  HomePage = 'homepage',
  Link = 'link',
}

export enum TriggerType {
  DragDrop = 'DRAG_DROP',
  Typing = 'TYPING',
  Null = 'null',
}

export enum TaskFilterType {
  NonSponsored = 'non-sponsored',
  Sponsored = 'sponsored',
}

export enum TaskFilterLabelId {
  NonSponsored = 'formField.label.task.list.selectTaskType.nonSponsored',
  Sponsored = 'formField.label.task.list.selectTaskType.sponsored',
}

export enum SearchParamNameTask {
  Profile = 'profile',
  Mode = 'mode',
  Page = 'page',
  Id = 'id',
}

export enum SearchParamName {
  Page = 'page',
  Size = 'size',
}

export enum TaskTab {
  Submit = 'submit',
  Priority = 'priority',
}

export enum ListFilter {
  IsSponsored = 'isSponsored',
  ImageRecognition = 'imageRecognition',
}

export enum FilterTab {
  SimpleSurvey = 'SimpleSurvey',
  Photo = 'Photo',
  Sponsored = 'Sponsored',
}

export enum VolumeUnitId {
  Packs = 'PACKS',
  Hectoliters = 'HECTOLITERS',
}

export interface GetTasks {
  page: number;
  pageSize: number;
  order?: 'priority';
  taskTemplateName?: string;
  signal?: AbortSignal;
  profile?: ProfileId;
  isSponsored?: boolean;
  imageRecognitionBucket?: ImageRecognitionBucketId;
}

export interface GetVisionJobIdList {
  page: number;
  pageSize: number;
  signal?: AbortSignal;
  profile: ProfileId;
}

export interface GetVisionJobId {
  visionJobId: string;
  signal?: AbortSignal;
}

export interface GetTask {
  taskId: string;
  signal?: AbortSignal;
}

export interface GetTaskDurations {
  signal?: AbortSignal;
}

export interface CreateTaskSimple {
  body: PayloadTaskSimple;
}

export interface CreateTaskSurvey {
  body: PayloadTaskSurvey;
}

export interface CreateTaskPhoto {
  body: PayloadTaskPhoto;
}

export interface UpdateTaskSimple {
  taskId: string;
  body: PayloadTaskSimple;
}

export interface UpdateTaskSurvey {
  taskId: string;
  body: PayloadTaskSurvey;
}

export interface UpdateTaskPhoto {
  taskId: string;
  body: PayloadTaskPhoto;
}

export interface DeleteTask {
  taskId: string;
  profile: ProfileId;
  isSponsored?: boolean;
}

export interface DeleteTaskPhoto {
  taskId: string;
}

export interface DeleteTaskPhotoResponse {
  message: string;
}

export interface TaskList {
  content: Task[];
  pagination: { pageSize: number; totalElements: number; totalPages: number };
}

export interface VisionJobIdList {
  content: VisionJobId[];
  pagination: { page: number; pageSize: number; totalElements: number; totalPages: number };
}

export interface VisionJobId {
  visionConfigId: string;
  description: string;
}

export interface TaskType {
  id: TaskTypeId;
  icon: string;
  profiles: ProfileId[];
  categories: CategoryId[];
  hasPermission: boolean;
}

export interface Task {
  id: string;
  name: string;
  templateTitle: string;
  description?: string;
  taskType: TaskTypeId;
  category: CategoryId;
  priority: number | null;
  duration: {
    duration: DurationId;
    minutes: number;
  } | null;
  configTaskEffectiveness?: {
    subType?: SubTypeId;
    skuList?: string[];
    challengeId?: string;
    minimumQuantity?: number;
    visionConfigId?: string;
    volumeUnit?: VolumeUnitId;
  } | null;
  frequency: FrequencyId | null;
  profile: ProfileId;
  submissionStatusCount?: SubmissionStatusCount;
  locked?: boolean;
  isSponsored: boolean;
  sponsoredBy: string | null;
  sponsoredById: string | null;
  imageRecognitionBucket?: ImageRecognitionBucketId | null;
  isCategoryValid: boolean;
}

export interface TaskSurvey {
  id: string;
  templateTitle: string;
  name: string;
  taskType: TaskTypeId;
  category: CategoryId;
  subTasks: SubTask[];
  priority: number | null;
  duration: {
    duration: DurationId;
    minutes: number;
  };
  frequency: FrequencyId;
  profile: ProfileId;
  vendorId: string;
  updatedBy: string;
  updatedById: string;
  createdAt: string;
  createdBy: string;
  createdById: string;
}

export interface TaskPhoto {
  isActive: boolean;
  id: string;
  templateTitle: string;
  name: string;
  taskType: TaskTypeId;
  category: CategoryId;
  priority: number | null;
  profile: ProfileId;
  vendorId: string;
  updatedAt: string;
  updatedBy: string;
  updatedById: string;
  createdAt: string;
  createdBy: string;
  createdById: string;
  imageRecognitionBucket: ImageRecognitionBucketId;
  isSponsored: boolean;
  sponsoredBy: string | null;
  sponsoredById: string | null;
  configTaskEffectiveness: {
    subType: SubTypeIdPhoto;
    visionConfigId: string;
  };
}

export interface Duration {
  duration: DurationId;
  minutes: number;
}

export interface Frequency {
  id: string;
  value: FrequencyId;
  isHidden?: boolean;
}

export interface CategoryDeprecated {
  id: string;
  taskTypes: TaskTypeId[];
  profiles: ProfileId[];
}

export interface Category {
  id: string;
  title: string;
}

export interface VolumeUnit {
  id: string;
  title: string;
}

export interface SubCategory {
  id: string;
  categories?: CategoryId[];
}

export interface SubType {
  id: SubTypeId;
  categories?: CategoryId[];
  addMethods: AddMethod[];
  effectivenessFormField: EffectivenessSimpleFormField;
}

export interface ImageRecognitionBucket {
  id: ImageRecognitionBucketId;
  categories?: CategoryId[];
}

export interface ConfigTaskEffectivenessSimple {
  subType?: SubTypeId;
  minimumQuantity?: number;
  challengeId?: string;
  skuList?: string[];
  volumeUnit?: VolumeUnitId;
}

export interface ConfigTaskEffectivenessPhoto {
  subType: SubTypeIdPhoto;
  visionConfigId: string;
}

export interface PayloadTask {
  name: string;
  templateTitle: string;
  profile: ProfileId;
  category: CategoryId;
  isSponsored?: boolean;
  sponsoredBy?: string | null;
  sponsoredById?: string | null;
}

export interface PayloadTaskSimple extends PayloadTask {
  duration: DurationId;
  frequency: FrequencyId;
  configTaskEffectiveness?: ConfigTaskEffectivenessSimple;
}
export interface PayloadTaskSurvey extends PayloadTask {
  duration: DurationId;
  frequency: FrequencyId;
  subTasks: SubTask[];
}

export interface PayloadTaskPhoto extends PayloadTask {
  imageRecognitionBucket: ImageRecognitionBucketId;
  configTaskEffectiveness: ConfigTaskEffectivenessPhoto;
}
export interface SubTask {
  id: string;
  title: string;
  isRequired: boolean;
  responseType: SurveyQuestionResponseId;
  options?: SubTasksOptions[];
}

export interface SubTasksOptions {
  id: string;
  value: string;
  jump?: SubTaskOptionsConditional;
}

export interface SubTaskOptionsConditional {
  position?: number;
  id?: string;
}

export interface TaskState {
  availableTaskTypes: TaskType[];
  availableCategoriesDeprecated: CategoryDeprecated[];
  availableCategories: Category[];
  availableSubTypesDeprecated: SubType[];
  availableSubTypes: SubType[];
  availableImageRecognitionBucketsDeprecated: ImageRecognitionBucket[];
  availableImageRecognitionBuckets: ImageRecognitionBucket[];
  initialListFilters: ListFilters;
  listFilters: ListFilters;
  availableVolumeUnits: VolumeUnit[];
}

export interface ListFilters {
  isSponsored?: boolean;
  imageRecognition?: ImageRecognitionBucketId;
}

export interface UpdateTaskPriority {
  body: {
    currentPriority: number | null;
    newPriority: number;
    templateId: string;
  };
  profile: ProfileId;
  isSponsored?: boolean;
}

export interface SyncTaskPriority {
  imageRecognitionBucket: ImageRecognitionBucketId;
  profile: ProfileId;
  isSponsored: boolean;
}

export interface UpdateAndSyncTaskPriority {
  templateId: string;
  currentPriority: number | null;
  newPriority: number;
  profile: ProfileId;
  isSponsored: boolean;
  imageRecognitionBucket: ImageRecognitionBucketId;
}

export interface OnSuccessPriorization {
  taskId: string;
  oldPriority: number;
  newPriority: number;
  method: TriggerType;
}

export interface CreateTaskSimpleResponse {
  id: string;
}

export interface CreateTaskPhotoResponse {
  id: string;
}

export interface CreateTaskCancellationResponse {
  TaskCancellationRequestId: string;
}

export interface TaskFilterOption {
  id: TaskFilterType;
  labelId: TaskFilterLabelId;
}

export interface UpdateTaskResponse {
  id: string;
}

export interface SubmissionCsvAccounts {
  accountId: string;
  minimum_amount_hectoliters: string;
  minimum_amount_packs: string;
}

export type CategoryOptimizelyEntry = [CategoryId, Category];
