import { setDefaultOptions } from 'date-fns';
import { enUS, es, ptBR } from 'date-fns/locale';
import { useEffect } from 'react';

export const dateLocales = {
  'pt-BR': ptBR,
  'en-US': enUS,
  'es-419': es,
};

interface UseAppLanguage {
  preferredLanguage: string | undefined;
}

function useAppLanguage({ preferredLanguage }: UseAppLanguage): void {
  useEffect(() => {
    if (preferredLanguage) {
      setDefaultOptions({
        locale: dateLocales[preferredLanguage] ?? dateLocales['en-US'],
      });
    }
  }, [preferredLanguage]);
}

export default useAppLanguage;
