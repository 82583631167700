import { useAppHeader } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import useAppLanguage from '../../hooks/useAppLanguage';
import useAppUserMetadata from '../../hooks/useAppUserMetadata';
import useMultiProfileFlag from '../../hooks/useMultiProfileFlag';
import useTaskCategoriesFlag from '../../hooks/useTaskCategoriesFlag';
import useUserTaskScopes from '../../hooks/useUserTaskScopes';
import PageError from '../../pages/PageError';
import PageLoading from '../../pages/PageLoading';

interface UserInfoProviderProps {
  children?: JSX.Element;
}

const UserInfoProvider = ({ children }: UserInfoProviderProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const [appHeaderConfig] = useAppHeader();

  const { isSuccessMetadata, hasErrorMetadata, vendorId, mutateUserMetadata, metadata } =
    useAppUserMetadata();
  const { isSuccessMultiProfileFlag, isMultiProfileFlagTimeout } = useMultiProfileFlag({
    vendorId,
  });
  const vendorIdSelected = appHeaderConfig?.selectedVendor;
  const preferredLanguage = metadata?.profile?.preferredLanguage;
  useAppLanguage({ preferredLanguage });
  const { isSuccess: isSuccessUserTaskScopes } = useUserTaskScopes();
  const { isSuccessTaskCategoriesFlag, isTaskCategoriesFlagTimeout } = useTaskCategoriesFlag();

  const doesNotHaveVendorId = isSuccessMetadata && !vendorId;

  const isUserInfoReady =
    isSuccessMetadata &&
    isSuccessMultiProfileFlag &&
    isSuccessUserTaskScopes &&
    isSuccessTaskCategoriesFlag &&
    vendorIdSelected;
  const hasErrorUserInfo =
    hasErrorMetadata ||
    isMultiProfileFlagTimeout ||
    isTaskCategoriesFlagTimeout ||
    doesNotHaveVendorId;

  if (!isUserInfoReady && !hasErrorUserInfo) {
    return <PageLoading />;
  }

  if (hasErrorUserInfo) {
    let errorMessageId = 'message.error.requestUserInfo';

    if (doesNotHaveVendorId) {
      errorMessageId = 'message.error.userWithoutVendorId';
    }

    return (
      <PageError
        error={{
          message: formatMessage({ id: errorMessageId }),
        }}
        onRetry={() => mutateUserMetadata()}
      />
    );
  }

  return children;
};

export default UserInfoProvider;
