import React from 'react';
import { Country } from '../../models/country';

export interface EnvConfig {
  name: string;
  ENV: string;
  ENV_TYPE: string;
  OPTIMIZELY_KEY: string | undefined;
  SEGMENT_KEY: Record<Country, string> | undefined;
}

export const defaultInitialEnvConfig: EnvConfig = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  OPTIMIZELY_KEY: '',
  SEGMENT_KEY: {
    [Country.BR]: '',
    [Country.US]: '',
    [Country.AR]: '',
    [Country.CO]: '',
    [Country.DO]: '',
    [Country.EC]: '',
    [Country.MX]: '',
    [Country.PA]: '',
    [Country.PE]: '',
    [Country.ZA]: '',
    [Country.HN]: '',
    [Country.SV]: '',
  },
};

export const EnvContext = React.createContext(defaultInitialEnvConfig);

export const useEnvContext = (): EnvConfig => React.useContext(EnvContext);

export const EnvProvider = ({
  env,
  children,
}: {
  env: EnvConfig;
  children: React.ReactNode;
}): JSX.Element => <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
