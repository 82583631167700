import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserMetadata } from 'admin-portal-shared-services';
import { v4 as uuidv4 } from 'uuid';
import { Profile, UserState } from '../../models/user';

const initialState = {
  profile: undefined,
  isMultiProfile: undefined,
  availableProfiles: undefined,
  vendorId: undefined,
  sessionId: uuidv4(),
  country: undefined,
  groups: undefined,
  metadata: {
    avatarUrl: undefined,
    userId: undefined,
    authorization: {
      groups: undefined,
      scopes: undefined,
    },
    profile: {
      preferredLanguage: undefined,
      preferredTimeFormat: undefined,
      timezone: undefined,
    },
    supportedCountries: undefined,
    vendors: undefined,
  },
} as UserState;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserMetadata: (state: UserState, action: PayloadAction<UserMetadata>) => {
      state.metadata = { ...state.metadata, ...action.payload };
    },
    setAvailableProfiles: (state: UserState, action: PayloadAction<Profile[]>) => {
      state.isMultiProfile = action.payload.length >= 2;
      state.availableProfiles = action.payload;
    },
    setProfile: (state: UserState, action: PayloadAction<Profile | undefined>) => {
      state.profile = action.payload;
    },
    setVendorId: (state: UserState, action: PayloadAction<string>) => {
      state.vendorId = action.payload;
    },
    setGroups: (state: UserState, action: PayloadAction<string[]>) => {
      const groups = action.payload.map((group) => ({
        groups: group,
      }));
      state.groups = groups;
    },
    setCountry: (state: UserState, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
  },
});

export const {
  setUserMetadata,
  setProfile,
  setAvailableProfiles,
  setVendorId,
  setGroups,
  setCountry,
} = userSlice.actions;

export default userSlice.reducer;
