const ENV: string | null = JSON.parse(localStorage.getItem('portal-config'))?.ENV || 'dev';

export const gotoForce = (host = ENV): void => {
  switch (host.toLowerCase()) {
    case 'dev':
      window.location.replace('http://localhost:8080/global');
      break;
    case 'qa':
      window.location.replace('https://ontap-admin-portal-sit.azurewebsites.net/global');
      break;
    case 'uat':
      window.location.replace('https://ontap-admin-portal-uat.azurewebsites.net/global');
      break;
    default:
      window.location.replace('https://ontap-admin-portal.azurewebsites.net/global');
  }
};
