import { useAnalyticsService } from 'admin-portal-shared-services';
import { useEffect } from 'react';
import useAppSelector from '../../hooks/useAppSelector';
import { Country } from '../../models/country';
import { UserState } from '../../models/user';
import { useEnvContext } from '../EnvProvider';

interface SegmentProviderProps {
  children: JSX.Element;
  segmentKey?: Record<Country, string>;
}

const SegmentProvider = ({ children, segmentKey }: SegmentProviderProps): JSX.Element => {
  const analyticsService = useAnalyticsService();
  const { SEGMENT_KEY } = useEnvContext();
  const { vendorId, metadata } = useAppSelector<UserState>((state) => state.user);
  const currentVendorId = metadata.vendors.find((vendor) => vendor.id === vendorId);
  const currentVendorIdCountry = currentVendorId.country as Country;
  const segmentKeyCountry = segmentKey
    ? segmentKey[currentVendorIdCountry]
    : SEGMENT_KEY[currentVendorIdCountry];

  useEffect(() => {
    if (segmentKeyCountry) {
      analyticsService.load(segmentKeyCountry);
    }
  }, [analyticsService, segmentKeyCountry]);

  return children;
};

export default SegmentProvider;
