import CustomerSatisfactionActiveBadge from '../assets/icons/category-badges/active/customer-satisfaction.svg';
import DigitalEducationActiveBadge from '../assets/icons/category-badges/active/digital-adoption.svg';
import ExecutionActiveBadge from '../assets/icons/category-badges/active/execution.svg';
import ValueCreationActiveBadge from '../assets/icons/category-badges/active/value-creation.svg';
import CustomerSatisfactionDisabledBadge from '../assets/icons/category-badges/disabled/customer-satisfaction.svg';
import DigitalEducationDisabledBadge from '../assets/icons/category-badges/disabled/digital-adoption.svg';
import ExecutionDisabledBadge from '../assets/icons/category-badges/disabled/execution.svg';
import ValueCreationDisabledBadge from '../assets/icons/category-badges/disabled/value-creation.svg';
import Icon from '../components/Icon';
import {
  Category,
  CategoryDeprecated,
  CategoryId,
  Frequency,
  FrequencyId,
  SubTask,
  SurveyQuestionResponseId,
  SurveyQuestionaryFormField,
  TaskFilterLabelId,
  TaskFilterOption,
  TaskFilterType,
  TaskTypeId,
} from '../models/task';
import { ProfileId } from '../models/user';

export const DEFAULT_CATEGORIES_DEPRECATED: CategoryDeprecated[] = [
  {
    id: CategoryId.CustomerSatisfaction,
    taskTypes: [TaskTypeId.Simple, TaskTypeId.Survey],
    profiles: [ProfileId.Bdr, ProfileId.NegBdr],
  },
  {
    id: CategoryId.DigitalEducation,
    taskTypes: [TaskTypeId.Simple, TaskTypeId.Survey],
    profiles: [ProfileId.Bdr, ProfileId.NegBdr],
  },
  {
    id: CategoryId.Execution,
    taskTypes: [TaskTypeId.Simple, TaskTypeId.Survey, TaskTypeId.Photo],
    profiles: [ProfileId.Bdr, ProfileId.NegBdr],
  },
  {
    id: CategoryId.ValueCreation,
    taskTypes: [TaskTypeId.Simple, TaskTypeId.Survey],
    profiles: [ProfileId.Bdr, ProfileId.NegBdr],
  },
];

export const DEFAULT_CATEGORIES: Category[] = [
  { id: CategoryId.CustomerSatisfaction, title: 'Customer Satisfaction' },
  { id: CategoryId.DigitalEducation, title: 'Digital Education' },
  { id: CategoryId.Execution, title: 'Execution' },
  { id: CategoryId.ValueCreation, title: 'Value Creation' },
  { id: CategoryId.PortfolioExpansion, title: 'Portfolio Expansion' },
  { id: CategoryId.VolumeRevenue, title: 'Volume Revenue' },
  { id: CategoryId.MarketplacePortfolioExpansion, title: 'Marketplace Portfolio Expansion' },
  { id: CategoryId.MarketplaceRevenue, title: 'Marketplace Revenue' },
  { id: CategoryId.ExecutionAbi, title: 'Execution ABI' },
  { id: CategoryId.ExecutionMarketplace, title: 'Execution Marketplace' },
  { id: CategoryId.MiNegocio, title: 'Mi Negocio' },
  { id: CategoryId.FinTech, title: 'Fintech' },
  { id: CategoryId.ChallengeAndRewards, title: 'Challenge and Rewards' },
  { id: CategoryId.OrderOnBEES, title: 'Order On BEES' },
  { id: CategoryId.DTaaS, title: 'DTaaS' },
  { id: CategoryId.SurveyDataCollection, title: 'Survey/Data Collection' },
  { id: CategoryId.OtherTasks, title: 'Other Tasks' },
];

export const AVAILABLE_FREQUENCIES: Frequency[] = [
  {
    id: 'everyVisit',
    value: FrequencyId.EveryVisit,
  },
  {
    id: 'monthly',
    value: FrequencyId.Monthly,
  },
  {
    id: 'effective',
    value: FrequencyId.Effective,
    isHidden: true,
  },
];

export const AVAILABLE_CATEGORIES_BADGES = {
  ACTIVE: {
    [CategoryId.CustomerSatisfaction]: (
      <Icon
        src={CustomerSatisfactionActiveBadge}
        data-testid="customer-satisfaction-category-active-icon"
      />
    ),
    [CategoryId.DigitalEducation]: (
      <Icon
        src={DigitalEducationActiveBadge}
        data-testid="digital-education-category-active-icon"
      />
    ),
    [CategoryId.Execution]: (
      <Icon src={ExecutionActiveBadge} data-testid="execution-category-active-icon" />
    ),
    [CategoryId.ValueCreation]: (
      <Icon src={ValueCreationActiveBadge} data-testid="value-creation-category-active-icon" />
    ),
  },
  DISABLED: {
    [CategoryId.CustomerSatisfaction]: (
      <Icon
        src={CustomerSatisfactionDisabledBadge}
        data-testid="customer-satisfaction-category-disabled-icon"
      />
    ),
    [CategoryId.DigitalEducation]: (
      <Icon
        src={DigitalEducationDisabledBadge}
        data-testid="digital-education-category-disabled-icon"
      />
    ),
    [CategoryId.Execution]: (
      <Icon src={ExecutionDisabledBadge} data-testid="execution-category-disabled-icon" />
    ),
    [CategoryId.ValueCreation]: (
      <Icon src={ValueCreationDisabledBadge} data-testid="value-creation-category-disabled-icon" />
    ),
  },
};

export const VENDOR_ID_MOCK = '44092477-b173-477a-a24a-25eeab20fccb';

export const ID_TASK_SURVEY_MOCK = '00000';
export const ID_TASK_SURVEY_SUBTASKS_MOCK = '00001';

export const ID_TASK_SIMPLE_MOCK = '00002';
export const ID_TASK_SIMPLE_WITH_EFFECTIVENESS_CHECK_MOCK = '00003';

export const ID_TASK_PHOTO_MOCK = '00004';
export const VISION_JOB_ID_MOCK = '00006';
export const INVALID_VISION_JOB_ID_MOCK = '00007';

export const ID_TASK_WITH_SUBMISSION_MOCK = '00004';

export const ID_SUBTASK_MOCK = '00008';

export const MODE_TAB_TASK_LIST_MOCK = 'submit';

export const CSV_EFFECTIVENESS_REQUIRED_COLUMN_NAME = 'sku';
export const CSV_EFFECTIVENESS_REQUIRED_FILE_MIME_TYPES = ['text/csv'];
export const CSV_EFFECTIVENESS_MODEL_FILE_NAME = 'model';
export const CSV_EFFECTIVENESS_MODEL_FILE_EXTENSION = 'csv';
export const CSV_EFFECTIVENESS_MODEL_BLOB = new Blob([`sku\n00001\n00002\n00003`], {
  type: 'text/csv',
});

export const EFFECTIVENESS_MINIMUM_QUANTITY_MIN_ALLOWED_VALUE = 1;
export const EFFECTIVENESS_MINIMUM_QUANTITY_MAX_ALLOWED_VALUE = 9999;

export const AVAILABLE_TASK_FILTER_TYPES: TaskFilterOption[] = [
  { id: TaskFilterType.NonSponsored, labelId: TaskFilterLabelId.NonSponsored },
  { id: TaskFilterType.Sponsored, labelId: TaskFilterLabelId.Sponsored },
];

export const EMPTY_SUBTASK_SURVEY = (id: string): SubTask => ({
  [SurveyQuestionaryFormField.Id]: id,
  [SurveyQuestionaryFormField.Title]: '',
  [SurveyQuestionaryFormField.ResponseType]: SurveyQuestionResponseId.Empty,
  [SurveyQuestionaryFormField.IsRequired]: true,
  [SurveyQuestionaryFormField.Options]: [],
});
