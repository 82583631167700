import MockAdapter from 'axios-mock-adapter/types';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash';
import {
  ENDPOINT_CREATE_TASK_CANCELLATION_REQUEST,
  ENDPOINT_GET_BDR_ALL_INFO,
  ENDPOINT_GET_CANCELLATION_CURRENT_PERIOD,
  ENDPOINT_GET_TASK_CANCELLATION_REQUEST,
  ENDPOINT_GET_TASK_CANCELLATION_REQUESTS,
  ENDPOINT_UPDATE_TASK_CANCELLATION_REQUEST,
} from '../../../consts/endpoint';
import { Country } from '../../../models/country';
import {
  BdrInfo,
  GetTaskCancellationRequestsResponse,
  ProjectionGetTaskCancellationRequest,
  TaskCancellationRequest,
  TaskCancellationStatus,
} from '../../../models/task-cancellation';
import { ProfileId } from '../../../models/user';

const taskCancellationHandlers = (mock: MockAdapter): void => {
  const cancellationRequestDefaultProperties = {
    country: Country.BR,
    updatedAt: '',
    updatedBy: '',
    vendorId: '',
    parameters: {
      dateRange: {
        startDate: '',
        endDate: '',
      },
    },
    bdrList: {
      list: [
        {
          id: '010203',
        },
      ],
    },
    accountList: {
      list: [],
    },
    taskList: {
      list: [],
    },
  };

  const cancellationRequestList: TaskCancellationRequest[] = [
    {
      _id: '0',
      name: 'With Cancellation Period - Task Cancellation Request Name Mock 0',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-06',
      ...cancellationRequestDefaultProperties,
      parameters: {
        dateRange: {
          startDate: '2023-06-05',
          endDate: '2023-06-25',
        },
      },
    },
    {
      _id: '1',
      name: 'Task Cancellation Request Name Mock 1',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '2',
      name: 'Searched Name - Task Cancellation Request 2',
      reason: 'Searched Reason - Task Cancellation Request',
      status: TaskCancellationStatus.CancellationRequested,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '3',
      name: 'With BDR IDs - Task Cancellation Request Name Mock 3',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.CancellationRequested,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
      bdrList: {
        list: [
          {
            id: '0f4b6e1d',
          },
          {
            id: '39c7af82',
          },
          {
            id: 'a2e950f7',
          },
          {
            id: '00000000',
          },
          {
            id: '5d31bc49',
          },
          {
            id: 'd98e3a15',
          },
          {
            id: '7b6fc813',
          },
        ],
      },
    },
    {
      _id: '4',
      name: 'Task Cancellation Request Name Mock 4',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.Processed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '5',
      name: 'Task Cancellation Request Name Mock 5',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.Processed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '6',
      name: 'Task Cancellation Request Name Mock 6',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.NotProcessed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '7',
      name: 'Task Cancellation Request Name Mock 7',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '8',
      name: 'Task Cancellation Request Name Mock 8',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.NotProcessed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '9',
      name: 'Task Cancellation Request Name Mock 9',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '10',
      name: 'Task Cancellation Request Name Mock 10',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '11',
      name: 'Task Cancellation Request Name Mock 11',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.CancellationRequested,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '12',
      name: 'Task Cancellation Request Name Mock 12',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '13',
      name: 'Task Cancellation Request Name Mock 13',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.NotProcessed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '14',
      name: 'Task Cancellation Request Name Mock 14',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.Processed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '15',
      name: 'Task Cancellation Request Name Mock 15',
      status: TaskCancellationStatus.Draft,
      reason: '',
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '16',
      name: 'Task Cancellation Request Name Mock 16',
      reason: 'Task Cancellation Request Reason Mock',
      period: '2023-04',
      status: TaskCancellationStatus.CancellationRequested,
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '17',
      reason: '',
      name: 'Task Cancellation Request Name Mock 17',
      period: '2023-04',
      status: TaskCancellationStatus.Draft,
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '18',
      name: 'Task Cancellation Request Name Mock 18',
      reason: 'Task Cancellation Request Reason Mock',
      period: '2023-04',
      status: TaskCancellationStatus.NotProcessed,
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '19',
      name: 'Task Cancellation Request Name Mock 19',
      status: TaskCancellationStatus.CancellationRequested,
      reason: 'Task Cancellation Request Reason Mock',
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '20',
      status: TaskCancellationStatus.Draft,
      name: 'Task Cancellation Request Name Mock 20',
      reason: '',
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '21',
      name: 'Task Cancellation Request Name Mock 21',
      status: TaskCancellationStatus.NotProcessed,
      reason: 'Task Cancellation Request Reason Mock',
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '22',
      name: 'Task Cancellation Request Name Mock 22',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '23',
      name: 'Task Cancellation Request Name Mock 23',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '24',
      name: 'Task Cancellation Request Name Mock 24',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.CancellationRequested,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '25',
      name: 'Task Cancellation Request Name Mock 25',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.NotProcessed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '26',
      name: 'Task Cancellation Request Name Mock 26',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.Processed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '27',
      name: 'Task Cancellation Request Name Mock 27',
      status: TaskCancellationStatus.Draft,
      reason: '',
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '28',
      name: 'Task Cancellation Request Name Mock 28',
      reason: 'Task Cancellation Request Reason Mock',
      period: '2023-04',
      status: TaskCancellationStatus.NotProcessed,
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '29',
      name: 'Task Cancellation Request Name Mock 29',
      reason: 'Task Cancellation Request Reason Mock',
      period: '2023-04',
      status: TaskCancellationStatus.Processed,
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '30',
      name: 'Task Cancellation Request Name Mock 30',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '31',
      name: 'Task Cancellation Request Name Mock 31',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '32',
      name: 'Task Cancellation Request Name Mock 32',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.CancellationRequested,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '33',
      name: 'Task Cancellation Request Name Mock 33',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.CancellationRequested,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '34',
      name: 'Task Cancellation Request Name Mock 34',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.Processed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '35',
      name: 'Task Cancellation Request Name Mock 35',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.Processed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '36',
      name: 'Task Cancellation Request Name Mock 36',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.NotProcessed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '37',
      name: 'Task Cancellation Request Name Mock 37',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '38',
      name: 'Task Cancellation Request Name Mock 38',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.NotProcessed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '39',
      name: 'Task Cancellation Request Name Mock 39',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '40',
      name: 'Task Cancellation Request Name Mock 40',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '41',
      name: 'Task Cancellation Request Name Mock 41',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.CancellationRequested,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '42',
      name: 'Task Cancellation Request Name Mock 42',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '43',
      name: 'Task Cancellation Request Name Mock 43',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.NotProcessed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '44',
      name: 'Task Cancellation Request Name Mock 44',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.Processed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '45',
      name: 'Task Cancellation Request Name Mock 45',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '46',
      name: 'Task Cancellation Request Name Mock 46',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.CancellationRequested,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '47',
      name: 'Task Cancellation Request Name Mock 47',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.Processed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '48',
      name: 'Task Cancellation Request Name Mock 48',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.NotProcessed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '49',
      name: 'Task Cancellation Request Name Mock 49',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '50',
      name: 'Task Cancellation Request Name Mock 50',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.NotProcessed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '51',
      name: 'Task Cancellation Request Name Mock 51',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.CancellationRequested,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '52',
      name: 'Task Cancellation Request Name Mock 52',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.Processed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '53',
      name: 'Task Cancellation Request Name Mock 53',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '54',
      name: 'Task Cancellation Request Name Mock 54',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '55',
      name: 'Task Cancellation Request Name Mock 55',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.CancellationRequested,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '56',
      name: 'Task Cancellation Request Name Mock 56',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.NotProcessed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '57',
      name: 'Task Cancellation Request Name Mock 57',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.Processed,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '58',
      name: 'Task Cancellation Request Name Mock 58',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '59',
      name: 'Task Cancellation Request Name Mock 59',
      reason: '',
      status: TaskCancellationStatus.Draft,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
    {
      _id: '60',
      name: 'Task Cancellation Request Name Mock 60',
      reason: 'Task Cancellation Request Reason Mock',
      status: TaskCancellationStatus.CancellationRequested,
      period: '2023-04',
      ...cancellationRequestDefaultProperties,
    },
  ];

  const bdrListDefaultProperties = {
    distributionCenterId: 0,
    isActive: true,
    profile: ProfileId.Bdr,
    sectorId: '',
    supervisorId: 0,
    updatedAt: '',
    updatedBy: '',
    vendorId: '',
    accounts: [
      {
        accountId: '',
        contractId: '',
      },
    ],
  };

  const bdrList: BdrInfo[] = [
    {
      bdrId: '0f4b6e1d',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: '39c7af82',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: 'a2e950f7',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: '5d31bc49',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: 'd98e3a15',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: '7b6fc813',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: 'e582039f',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: '23a174c8',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: 'f7e816b0',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: 'c41d9b7e',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: '1a0b6f8e',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: '823f4c59',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: 'e4b6d07a',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: '296fc3d8',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: 'd8e9154a',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: '7f3a6e2b',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: '49a8c6f0',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: 'c920d7e5',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: '0b6d9a4f',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: 'a4f31d9b',
      ...bdrListDefaultProperties,
    },
    {
      bdrId: '018a8dce',
      ...bdrListDefaultProperties,
    },
  ];

  mock.onPost(`${ENDPOINT_CREATE_TASK_CANCELLATION_REQUEST}`).reply(200, {
    message: 'Cancellation Request Created successfully',
  });

  mock.onGet(`${ENDPOINT_GET_TASK_CANCELLATION_REQUESTS}`).reply(({ params, headers }) => {
    let content = cloneDeep(cancellationRequestList);
    const response: GetTaskCancellationRequestsResponse = {
      content: null,
      pagination: {
        totalPages: null,
        totalElements: null,
        page: params.page + 1,
        pageSize: params.pageSize,
      },
    };

    const hasEmptyContent = headers.country === Country.BR;
    if (hasEmptyContent) {
      content = [];
    }

    const sliceEndIndex = params.pageSize * (params.page + 1);
    const sliceStartIndex = sliceEndIndex - params.pageSize;

    const hasNameOrReasonParam = !!params.nameOrReason;
    if (hasNameOrReasonParam) {
      const searchTerm = params.nameOrReason?.toLowerCase();
      const searchItems = [...content];
      const searchResult = searchItems.filter(
        (searchedItem) =>
          searchedItem.name.toLocaleLowerCase().includes(searchTerm) ||
          searchedItem.reason.toLocaleLowerCase().includes(searchTerm)
      );

      response.content = searchResult.slice(sliceStartIndex, sliceEndIndex);
      response.pagination.totalPages = Math.ceil(searchResult.length / params.pageSize);
      response.pagination.totalElements = searchResult.length;
    } else {
      response.content = content.slice(sliceStartIndex, sliceEndIndex);
      response.pagination.totalPages = Math.ceil(content.length / params.pageSize);
      response.pagination.totalElements = content.length;
    }

    return [200, response];
  });

  mock
    .onPut(new RegExp(`${ENDPOINT_UPDATE_TASK_CANCELLATION_REQUEST({ requestId: '*' })}`))
    .reply(200, {
      message: 'Cancellation Request Updated Successfully',
    });

  mock
    .onGet(
      new RegExp(
        `${ENDPOINT_GET_TASK_CANCELLATION_REQUEST({
          requestId: '*',
        })}`
      )
    )
    .reply(({ params, url }) => {
      let status: number;
      let content: TaskCancellationRequest | Blob;

      const urlPaths = url.split('/');
      const requestId = urlPaths[urlPaths.length - 1];

      const hasParamsCsv =
        !!params?.projection && params?.projection === ProjectionGetTaskCancellationRequest.Csv;
      if (hasParamsCsv) {
        content = new Blob([`requestId\n${requestId}`], {
          type: 'text/csv',
        });
        status = 200;

        return [status, content];
      }

      const requestSelected = cancellationRequestList.find(
        (cancellationRequest) => cancellationRequest._id.toLowerCase() === requestId.toLowerCase()
      );
      if (!!requestSelected) {
        content = requestSelected;
        status = 200;
        return [status, content];
      } else {
        status = 404;
        return [status];
      }
    });

  mock.onGet(new RegExp(ENDPOINT_GET_BDR_ALL_INFO({ bdrId: '*' }))).reply(({ url }) => {
    let status: number;
    let content: BdrInfo;

    const urlPaths = url.split('/');
    const bdrId = urlPaths[urlPaths.length - 1];

    const bdrSelected = bdrList.find((bdr) => bdr.bdrId.toLowerCase() === bdrId.toLowerCase());
    if (!!bdrSelected) {
      content = bdrSelected;
      status = 200;
      return [status, content];
    } else {
      status = 404;
      return [status];
    }
  });

  mock.onGet(ENDPOINT_GET_CANCELLATION_CURRENT_PERIOD).reply(() => {
    const currentPeriod = format(new Date(), 'yyyy-MM');
    const response = { period: currentPeriod };
    const status = 200;

    return [status, response];
  });
};

export default taskCancellationHandlers;
