import { useDecision } from '@optimizely/react-sdk';
import { useAppHeader, useUserMetadata } from 'admin-portal-shared-services';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { axiosInstance } from '../../api';
import { gotoForce } from '../../api/services/host';
import { OPTIMIZELY_KEY_FORCE_HUB_MIGRATION } from '../../consts/optimizely';
import PageError from '../../pages/PageError';
import PageLoading from '../../pages/PageLoading';

interface IAppHeaderProviderProps {
  children?: JSX.Element;
}

const AppHeaderProvider = ({ children }: IAppHeaderProviderProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { data: metadata, hasError, mutateUserMetadata } = useUserMetadata();
  const [appHeaderConfig, setAppHeaderConfig] = useAppHeader();
  const [isLoading, setIsLoading] = useState<boolean>(!appHeaderConfig.selectedVendor);
  const [countryPermission, setCountryPermission] = useState<string[] | undefined>();

  const zoneEu = ['ID', 'PH'];

  //FEATURE_FLAG (BEESOT-36931) - Decision flag on redirect to ONTAP or stay in Hub based on VendorId and Country
  //Remove after BEES Hub migration is complete
  const [ForceHubMigrationFlag, isForceHubMigrationFlagRead] = useDecision(
    OPTIMIZELY_KEY_FORCE_HUB_MIGRATION
  );

  function getCountryMatch<Array>(userCountry: Array[], optimizelyCountry: Array[]): Array[] {
    const optimizelyCountries = userCountry.filter((item) => optimizelyCountry.includes(item));
    const allowedCountries = optimizelyCountries.filter((country) =>
      metadata.vendors.some((vendor) => vendor.country === country)
    );
    return allowedCountries;
  }
  useEffect(() => {
    if (
      isForceHubMigrationFlagRead &&
      !!metadata &&
      !!metadata?.supportedCountries &&
      !!metadata?.vendors
    ) {
      const optReturn = ForceHubMigrationFlag?.variables?.vendorList;
      const vendorList = [];
      if (optReturn && optReturn.hasOwnProperty('hubAccess')) {
        const countryPermissionOptimizely = optReturn['hubAccess'].map((data) => data.country);
        const allowedCountries = getCountryMatch(
          _.flatten([metadata.supportedCountries]),
          countryPermissionOptimizely
        );
        setCountryPermission(allowedCountries);
        optReturn['hubAccess'].forEach((countryAccess) => {
          const vendorListHub = metadata.vendors.filter(
            (element) => element.country === countryAccess.country
          );
          countryAccess['vendorList'].forEach((vendorWitchAccess) => {
            const vendor = vendorListHub.find(
              (element) => element.id === vendorWitchAccess.vendorId
            );
            if (vendor) vendorList.push(vendor);
          });
        });
        if (vendorList && vendorList.length === 0) {
          gotoForce();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForceHubMigrationFlagRead, metadata?.vendors]);

  useEffect(() => {
    if (appHeaderConfig.selectedVendor) {
      const isZoneEu = zoneEu.includes(appHeaderConfig.selectedCountry);

      axiosInstance.defaults.baseURL = isZoneEu ? '/api/eu' : '/api';
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 0);
    }
  }, [appHeaderConfig.selectedVendor]);

  useEffect(() => {
    setAppHeaderConfig({
      countryOptions: countryPermission,
    });
  }, [setAppHeaderConfig, countryPermission]);

  let errorMessageId = 'message.error.requestUserInfo';

  const doesNotHaveVendorId = metadata && !metadata?.vendors;
  const doesNotHaveSupportedCountries = metadata && !metadata?.supportedCountries;

  if (doesNotHaveVendorId && doesNotHaveSupportedCountries) {
    errorMessageId = 'message.error.userWithoutCountryAndVendor';
  } else if (doesNotHaveVendorId) {
    errorMessageId = 'message.error.userWithoutVendorId';
  } else if (doesNotHaveSupportedCountries) {
    errorMessageId = 'message.error.userWithoutCountry';
  }

  if (hasError || doesNotHaveVendorId || doesNotHaveSupportedCountries) {
    return (
      <PageError
        error={{
          message: formatMessage({ id: errorMessageId }),
        }}
        onRetry={() => mutateUserMetadata()}
      />
    );
  }

  if (isLoading) {
    return <PageLoading />;
  }

  return children;
};

export default AppHeaderProvider;
