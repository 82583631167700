import { useDecision } from '@optimizely/react-sdk';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { OPTIMIZELY_KEY_PHOTO_TASK } from '../../consts/optimizely';
import {
  ImageRecognitionBucketId,
  ListFilters,
  TaskState,
  TaskType,
  TaskTypeId,
} from '../../models/task';
import {
  setAvailableTaskTypes,
  setInitialListFilters,
  setListFilters,
} from '../../redux/slices/taskSlice';
import useAppDispatch from '../useAppDispatch';
import useAppSelector from '../useAppSelector';
import useHasTaskScopes from '../useHasTaskScopes';

function useUserTaskScopes(): { isSuccess: boolean } {
  const { availableTaskTypes } = useAppSelector<TaskState>((state) => state.task);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const taskScopes = useHasTaskScopes();
  // #FEATURE_FLAG (BEESOT-36832): Will be removed when phototask is fully delivered
  const [photoTaskFlag, isPhotoTaskFlagReady, isPhotoTaskFlagTimeout] =
    useDecision(OPTIMIZELY_KEY_PHOTO_TASK);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const hasSimpleSurveyFilter =
      taskScopes.SIMPLE.hasTaskTemplateRead ||
      taskScopes.SIMPLE.hasTaskTemplateWrite ||
      taskScopes.SURVEY.hasTaskTemplateRead ||
      taskScopes.SURVEY.hasTaskTemplateWrite;
    const hasPhotoFilter =
      taskScopes.IMAGE_RECOGNITION.hasTaskTemplateRead ||
      taskScopes.IMAGE_RECOGNITION.hasTaskTemplateWrite;

    const listFilters: ListFilters = {
      imageRecognition: ImageRecognitionBucketId.None,
      isSponsored: false,
    };

    if (hasSimpleSurveyFilter) {
      dispatch(setInitialListFilters(listFilters));
      dispatch(setListFilters(listFilters));
      return;
    }

    if (hasPhotoFilter) {
      listFilters.imageRecognition = ImageRecognitionBucketId.Shelf;
      dispatch(setInitialListFilters(listFilters));
      dispatch(setListFilters(listFilters));
      return;
    }

    throw new Error(formatMessage({ id: 'message.error.userWithoutGroups' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPhotoTaskFlagReady) {
      let taskTypesAvailable: TaskType[];

      if (!photoTaskFlag.enabled || isPhotoTaskFlagTimeout) {
        taskTypesAvailable = cloneDeep(
          availableTaskTypes.filter((taskType) => taskType.id !== TaskTypeId.Photo)
        );
      } else {
        taskTypesAvailable = cloneDeep(availableTaskTypes);
      }

      taskTypesAvailable.forEach((taskType) => {
        const hasTaskTypePermission = taskScopes[taskType.id].hasTaskTemplateWrite;
        if (!hasTaskTypePermission) {
          taskType.hasPermission = false;
        }
      });

      dispatch(setAvailableTaskTypes(taskTypesAvailable));
      setIsSuccess(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPhotoTaskFlagReady]);

  return {
    isSuccess,
  };
}

export default useUserTaskScopes;
