import { createGlobalStyle } from 'styled-components';
import { BREAKPOINT_MOBILE } from '../consts/screen';
import { FORCE_HUB_CLASSNAME_PREFIX } from '../consts/theme';
import { StyledContainer } from '../providers/AppThemeProvider/AppThemeProvider.styles';

export const ResetStyles = createGlobalStyle`
    .${FORCE_HUB_CLASSNAME_PREFIX} * {
    }

    button {
        background: none;
        border: none;
        padding: 0;
    }
`;

export const GlobalStyles = createGlobalStyle`
    .${FORCE_HUB_CLASSNAME_PREFIX} * {
        box-sizing: border-box;
    }

    img {
        display: block;
        max-width: 100%;
    }
`;

export const HideAppHeader = createGlobalStyle`
    #mfe-content-header {
        display: none;
    }

    ${StyledContainer} {
        min-height: calc(100vh - 56px);

        @media (max-width: ${BREAKPOINT_MOBILE}) {
            min-height: calc(100vh - 112px);
        }
    }
`;
